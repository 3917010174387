<template>
    <div class="chat-vue add_quasar" ref="chat-vue">
        <div v-if="switchChat.url_chat!==''"
             :style="{display:this.interactive===true?'block':'none'}"
             :id="interactive === 'admin' ? 'chat-admin' :'chat-iframe'"
             :ref="interactive === 'admin' ? 'chat-admin' :'chat-iframe'">
            <div v-if="room">
                <div id="box" ref="box" class="box" :style="boxStyle" @xmousedown="mousedownBox" @xmouseup="mouseupBox" @xmousemove="mousemoveBox">
                    <!--                <q-resize-observer @resize="onResize" />-->
                    <div v-if="this.$app.isMobile" class="close-chat-btn text-white" style="opacity: 1; z-index: 99999; cursor: pointer;" v-on:click="closeChatMobile">
                        <svg width="14" height="14" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8203 1.35156L7.17188 6L11.8203 10.6484L10.6484 11.8203L6 7.17188L1.35156 11.8203L0.179688 10.6484L4.82812 6L0.179688 1.35156L1.35156 0.179688L6 4.82812L10.6484 0.179688L11.8203 1.35156Z" fill="white"/>
                        </svg>
                    </div>
                    <div id="participants" ref="participants-box" @click="checkFocus()" class="participants-box" :style="participantsStyle" v-if="participantsVisible" @mousedown="mousedown" @mouseup="mouseup" @mousemove="mousemove">
                        <q-card style="background: none; opacity: 1;">
                            <q-expansion-item
                                expand-separator
                                switch-toggle-side
                                icon="people"
                                :header-class="!connected ? 'text-negative' : ''"
                                :label="localeMessages.participantes+' | ' + users.length + (connected !== true ? ' ***OFFLINE***':'')"
                                v-model="elements.participants.expanded"
                            >
                                <q-card-section id="participants-list" style="overflow-y: scroll;overflow-x: hidden;" class=" text-subtitle2" >
                                    <q-list style="max-height: 10vh !important;" dense>
                                        <q-item :key="'participants'+index" v-for="(user, index) in users" >
                                            <q-item-section avatar>
                                                <q-btn v-if="user.id!==id" flat round color="var(--chat_text_color)" icon="more_vert">
                                                    <q-menu content-class="add_quasar" >
                                                        <q-list style="min-width: 100px">
                                                            <q-item class="btn-option-chat" clickable v-close-popup>
                                                                <q-item-section @click="$app.externalEventHandler({detail:{data:{type:'user_chat_complaint',user_id:user.id}}})">{{ reportUser }}</q-item-section>
                                                            </q-item>
                                                            <q-separator></q-separator>
                                                            <q-item class="btn-option-chat" v-if="switchChat.Habilitar_Amigos && allRelatedFriends.indexOf(Number(user.id))===-1" clickable v-close-popup>
                                                                <q-item-section @click="$app.externalEventHandler({detail:{data:{type:'user_chat_add_friend',user_id:user.id}}})">{{ addFriend  }}</q-item-section>
                                                            </q-item>
                                                            <q-separator></q-separator>
                                                            <q-item class="btn-option-chat" clickable v-close-popup>
                                                                <q-item-section @click="openPrivateMessage(Number(user.id))">{{ $i18n.t('pm.sendMessage') }}</q-item-section>
                                                            </q-item>
                                                        </q-list>
                                                    </q-menu>
                                                </q-btn>
                                            </q-item-section>
                                            <q-item-section>{{user.name}}</q-item-section>
                                            <q-item-section avatar v-if="user.status === 'none'">
                                                <q-icon name="cancel" color="red"/>
                                            </q-item-section>
                                            <!--<q-item-section avatar v-if="user.status === 'off-line'">
                                            <q-icon name="bi-person-plus" />
                                            </q-item-section>
                                            <q-item-section avatar v-if="user.status === 'on-line'">
                                            <q-icon name="bi-person-check" color="green"/>
                                            </q-item-section>-->
                                        </q-item>
                                    </q-list>
                                </q-card-section>
                            </q-expansion-item>
                        </q-card>
                    </div>
                    <div id="chat" ref="chat-box" @click="checkFocus()" class="chat-box" :style="chatStyle" @mousedown="mousedownChat" @mouseup="mouseupChat" @mousemove="mousemoveChat">
                        <q-card style="background: none; opacity: 1;" class=" text-white">
                            <q-expansion-item expand-separator switch-toggle-side v-model="elements.chat.expanded" >
                                <template v-slot:header>
                                    <q-item-section>
						<span>{{localeMessages.chat}}
						<q-badge color="red" v-if="unreadMessagesCount > 0 && !elements.chat.expanded">{{ unreadMessagesCount }}</q-badge>
						</span>
                                    </q-item-section>

                                    <q-item-section side>
                                        <div class="row items-center">
                                            <q-btn flat v-show="roomHasAudioStream" class="q-mr-md" v-if="!hasVolume" icon="volume_off" color="silver" @click="toggleHasVolume"/>
                                            <q-btn flat v-show="roomHasAudioStream" class="q-mr-md" v-if="hasVolume" icon="volume_up" color="white" @click="toggleHasVolume"/>
                                            <q-btn class="overflow-hidden" flat v-show="roomHasAudioStream" v-if="!canSpeak" icon="mic_off" color="silver" @click="toggleCanSpeak"/>
                                            <q-btn class="overflow-hidden" flat v-show="roomHasAudioStream" v-if="canSpeak"  icon="mic" color="white" @click="toggleCanSpeak"/>
                                        </div>
                                    </q-item-section>
                                </template>

                                <q-card-section id="messages-list" ref="messages" style="overflow-y: scroll;" class="text-white text-subtitle2 hide-scrollbar" v-if="elements.chat.expanded" @scroll="scrollMessages">
                                    <q-list :style="chatheight" style="min-height: 150px !important;" dense >
                                        <q-item  class="text-white" :key="'messages'+index" v-for="(message, index) in messages">
                                            <q-item-section class="q-mb-md">
                                                <q-item-label v-if="message.name"><strong>{{message.name}}:</strong></q-item-label>
                                                <q-item-label v-html="message.message" :value="message.message"></q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-card-section>

                                <q-card-section v-if="!isLive || (!isBanned && allow_chat)">

                                    <div class="row q-gutter-xs" style="clear:both;">
                                        <div class="col-1" style="margin-right: 15px;">
                                            <q-avatar @click="showEmijiBox = !showEmijiBox">
                                                <q-icon name="fas fa-smile" style="font-size: 1em;"/>
                                            </q-avatar>
                                        </div>
                                        <div class="col">

                                            <div contentEditable="true" class="message-div" ref="txtmessage" v-html="textMessage" @keydown="messageCharCount" @keyup.enter="sendMessage"></div>

                                        </div>

                                        <div class="col-1">
                                            <q-avatar @click="sendMessage">
                                                <q-icon name="bi-cursor" style="font-size: 1em;"/>
                                            </q-avatar>
                                        </div>

                                    </div>

                                </q-card-section>
                                <div>
                                    <div class="q-gutter-xs" id="emoji-box" style="background-color:black; margin-left: 0; float: left;  min-width: 350px !important;" v-show="showEmijiBox" >
                                        <div id="painel" style="margin-left:65px;margin-right:65px;">
                                            <div style="margin:5px; float: left; max-width: 21px !important; min-width: 21px !important;" @click="addEmoji(emoji)"
                                                 :key="'emoji'+index" v-for="(emoji, index) in emojis" v-html="emoji"></div>
                                        </div>
                                    </div>
                                </div>
                            </q-expansion-item>
                        </q-card>
                    </div>

                </div>
            </div>
        </div>
<!--        <iframe v-if="this.switch.url_chat!==''" :id="interactive == true ? 'chat-iframe' : 'chat-admin'" :ref="interactive == true ? 'chat-iframe' : 'chat-admin'" :src="$url + 'ws/index.html?' +
        'id=' + id + 
        '&nickname=' + nickname + 
        '&max=' + max + 
        '&audio=' + audio + 
        '&participants=' + participants + 
        '&interactive=' + interactive + 
        '&t__=' + token + 
        '&age=' + age + 
        '&locale=' + locale +
        '&friends=' + (this.switch.Habilitar_Amigos?1:0) +
        '&room=' + room" frameborder="0"
        allow="camera; microphone;"></iframe>-->
    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters, mapActions } from "vuex";
    import stringSimilarity from "string-similarity";
    import Peer from "peerjs";
    import {io} from "socket.io-client";
    import {throttle} from "quasar";
    import {focusUnity} from "@/helper";
    let emitter = require('tiny-emitter/instance');
    export default {
        name: 'Chat',
        props: ['max', 'audio', 'room', 'participants', 'interactive', 'locale'],
        data: function () {
            return {
                localeMessages: {
                    chat: this.$i18n.t('chat.chat'),
                    participantes: this.$i18n.t('chat.participantes'),
                    msg1: this.$i18n.t('chat.mic'),
                    msg2: this.$i18n.t('chat.volume'),
                },
                addFriend: this.$i18n.t('chat.AdicionarFriend'),
                reportUser: this.$i18n.t('chat.DenunciarUser'),
                socket: null,
                currentRoom: null,
                connected: false,
                messagesLimit: 500,
                unreadMessagesCount: 0,
                peerServerConfig: {
                    host: (this.$url_api.indexOf('api.') !== -1) ? ("peerjs."+location.host) : ("peerjs-"+location.host),
                    secure: true,
                    path: "/myapp",
                    // initiator: false,
                    // channelConfig: {},
                    // channelName: 'nba001',
                    //config: { iceServers: [{ urls: "turn:coturn.avsola.vrglass.com:3478", username: "teste", credential: "teste" }, { urls: "stun:coturn.avsola.vrglass.com:3478" } /*,{ urls: "stun:stun.l.google.com:19302" }, { urls: "stun:stun1.l.google.com:19302" }, { urls: "stun:stun2.l.google.com:19302" }, { urls: "stun:stun3.l.google.com:19302" }, { urls: "stun:stun4.l.google.com:19302" }*/] },
                    config: {
                        iceServers: [
                            { urls: ["stun:sp-turn1.xirsys.com"] },
                            {
                                username: "x92v6GZMgfR7vbsXe6P3IkhkXcaryZuWOMtdV1E_EcMD1Lq-ehWKabfuI86D9cn0AAAAAGHMaKh2cmdsYXNzZGV2",
                                credential: "e3379e78-68ae-11ec-a498-0242ac120004",
                                urls: [
                                    "turn:sp-turn1.xirsys.com:80?transport=udp",
                                    "turn:sp-turn1.xirsys.com:3478?transport=udp",
                                    "turn:sp-turn1.xirsys.com:80?transport=tcp",
                                    "turn:sp-turn1.xirsys.com:3478?transport=tcp",
                                    "turns:sp-turn1.xirsys.com:443?transport=tcp",
                                    "turns:sp-turn1.xirsys.com:5349?transport=tcp",
                                ],
                            },
                        ],
                    },
                    // offerOptions: {},
                    // answerOptions: {},
                    // sdpTransform: function (sdp) { return sdp },
                    // stream: false,
                    // streams: [],
                    // trickle: true,
                    // allowHalfTrickle: false,
                    // wrtc: {}, // RTCPeerConnection/RTCSessionDescription/RTCIceCandidate
                    // objectMode: false
                },
                peer: null,
                users: [
                    // {
                    //   id:1,
                    //   name:'Flávio Brito',
                    //   status: 'on-line'
                    // }
                ],
                autoScroll: true,
                participantsVisible: true,
                usersStreams: {},
                messages: [],
                textMessage: null,
                roomHasAudioStream: false,
                hasVolume: true,
                canSpeak: true,
                showEmijiBox: false,
                boxDragAndDrop: true,
                messageMaxLenght: 100,
                emojis: [
                    '<i class="em em-bird" aria-role="presentation" aria-label="BIRD"></i>',
                    '<i class="em em---1" aria-role="presentation" aria-label="THUMBS UP SIGN"></i>',
                    '<i class="em em--1" aria-role="presentation" aria-label="THUMBS DOWN SIGN"></i>',
                    '<i class="em em-angry" aria-role="presentation" aria-label="ANGRY FACE"></i>',
                    '<i class="em em-anguished" aria-role="presentation" aria-label="ANGUISHED FACE"></i>',
                    '<i class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i>',
                    '<i class="em em-basketball" aria-role="presentation" aria-label="BASKETBALL AND HOOP"></i>',
                    '<i class="em em-100" aria-role="presentation" aria-label="HUNDRED POINTS SYMBOL"></i>',
                    '<i class="em em-expressionless" aria-role="presentation" aria-label="EXPRESSIONLESS FACE"></i>',
                    '<i class="em em-face_with_hand_over_mouth" aria-role="presentation" aria-label="SMILING FACE WITH SMILING EYES AND HAND COVERING MOUTH"></i>',
                    '<i class="em em-face_with_monocle" aria-role="presentation" aria-label="FACE WITH MONOCLE"></i>',
                    '<i class="em em-face_with_raised_eyebrow" aria-role="presentation" aria-label="FACE WITH ONE EYEBROW RAISED"></i>',
                    '<i class="em em-face_with_rolling_eyes" aria-role="presentation" aria-label="FACE WITH ROLLING EYES"></i>',
                    '<i class="em em-dizzy_face" aria-role="presentation" aria-label="DIZZY FACE"></i>',
                    '<i class="em em-fire" aria-role="presentation" aria-label="FIRE"></i>',
                    '<i class="em em-first_place_medal" aria-role="presentation" aria-label="FIRST PLACE MEDAL"></i>',
                    '<i class="em em-hugging_face" aria-role="presentation" aria-label="HUGGING FACE"></i>',
                    '<i class="em em-man-facepalming" aria-role="presentation" aria-label=""></i>',
                    '<i class="em em-leg" aria-role="presentation" aria-label="LEG"></i>',
                    '<i class="em em-man-frowning" aria-role="presentation" aria-label=""></i>',
                    '<i class="em em-man-gesturing-no" aria-role="presentation" aria-label=""></i>',
                    '<i class="em em-man-bowing" aria-role="presentation" aria-label=""></i>',
                    '<i class="em em-mask" aria-role="presentation" aria-label="FACE WITH MEDICAL MASK"></i>',
                    '<i class="em em-raised_hands" aria-role="presentation" aria-label="PERSON RAISING BOTH HANDS IN CELEBRATION"></i>',
                    '<i class="em em-phone" aria-role="presentation" aria-label="BLACK TELEPHONE"></i>',
                    '<i class="em em-sparkling_heart" aria-role="presentation" aria-label="SPARKLING HEART"></i>',
                    '<i class="em em-sunglasses" aria-role="presentation" aria-label="SMILING FACE WITH SUNGLASSES"></i>',
                    '<i class="em em-stuck_out_tongue_closed_eyes" aria-role="presentation" aria-label="FACE WITH STUCK-OUT TONGUE AND TIGHTLY-CLOSED EYES"></i>',
                    '<i class="em em-tired_face" aria-role="presentation" aria-label="TIRED FACE"></i>',
                    '<i class="em em-thinking_face" aria-role="presentation" aria-label="THINKING FACE"></i>',
                    '<i class="em em-zap" aria-role="presentation" aria-label="HIGH VOLTAGE SIGN"></i>',
                ],
                elements: {
                    box: {
                        height: "250px",
                        width: "400px",
                        //position: "position: fixed; top: 50px; right: 4vw;",
                    },
                    participants: {
                        height: "250px",
                        width: "400px",
                        expanded: false,
                        // position: "position: fixed; top: 50px; right: 4vw;",
                    },
                    chat: {
                        height: "150px",
                        width: "400px",
                        expanded: false,
                        // position: "position: fixed; top: 350px; right: 4vw;",
                    },
                },
                sentMessages: [],
                allRelatedFriends:[],
                isBanned:false,
                isLive:false,
                allow_chat:true,
            };
        },
        watch: {
            "elements.chat.expanded": function (newValue, oldValue) {
                oldValue;
                if (newValue === true && this.autoScroll) {
                    this.scroll();
                }
            },
        },
        computed: {
            ...mapGetters([
                'logged', 'token', 'id', 'nickname', 'email', 'ref', 'age','switch',"checkMobile","current_session","sessions"
            ]),
            participantsStyle() {
                return "";
                //if (!this.elements.participants.expanded) return this.elements.participants.position + `width:${this.elements.participants.width}`;
                //return this.elements.participants.position + `height:${this.elements.participants.height};width:${this.elements.participants.width}`;
            },
            chatheight() {
                return `height: ${this.elements.chat.height} !important;`;
            },
            chatStyle() {
                return "";
                //console.log("SSA", this.elements.chat.position);
                //if (!this.elements.chat.expanded) return this.elements.chat.position + `width:${this.elements.chat.width}`;
                //return this.elements.chat.position + `height:${this.elements.chat.height};width:${this.elements.chat.width}`;
            },
            boxStyle() {
                if (!this.elements.box.expanded)
                    return (
                        this.elements.box.position + `width:${this.elements.box.width}`
                    );
                return (
                    this.elements.box.position +
                    `height:${this.elements.box.height};width:${this.elements.box.width}`
                );
            },
            switchChat(){
                return this.switch;
            }
        },
        mounted() {
            let root = this;
            if ((this.nickname !== '') && (this.nickname != null))
            {
                this.setServerPeer();
                this.setLocale(this.locale);
                if (this.interactive === true) {
                    window.scrollTo(0,document.body.scrollHeight)
                    if (this.current_session.live_chat_id !== null){
                        this.isLive = true;
                        axios.get(root.$url_api + 'api/live/getUser',{
                            params:{id:root.current_session.live_chat_id,},
                            headers: {
                                'Content-Type': 'application/json;charset=UTF-8',
                                'Access'      : 'application/json',
                                'Authorization': 'Bearer ' + this.token,
                            }
                        }).then(response => {
                            //isBanned
                            root.isBanned = response.data.isBanned;
                            root.joinRoom(root.room, root.max, root.audio, true)
                        })
                    }else{
                        this.joinRoom(this.room, this.max, this.audio, true)
                    }
                    if (window.innerWidth < 650) {
                        this.setChatExpanded(false)
                        this.setParticipantsExpanded(false)
                        this.setBoxPosition("position: absolute; bottom: 0; right: 0;")
                        this.setBoxDimension("100%", "300px")
                        this.setChatDimension("350px", "150px")
                    } else {
                        this.setChatExpanded(false)
                        this.setParticipantsExpanded(false)
                        this.setBoxPosition("position: absolute; bottom: 0; right: 0;")
                        this.setBoxDimension("100%", "300px")
                        this.setChatDimension("350px", "30vh")
                    }
                    this.setBoxDragAndDrop(true)
                    this.setMessageMaxLenght(280)
                    this.setParticipantsVisible(this.participants);

                } else if (this.interactive === 'admin') {
                    this.connectServer()
                }else {
                    this.joinRoom(this.room, 9999999, false, true)
                }
            }
        },
        methods: {
            ...mapActions([
                "update_switch","update_sessions","update_current_session","update_id_form_actual"
            ]),
            closeChatMobile()
            {
                this.$app.openChatButton = !this.$app.openChatButton;
                console.log('This button => ', this.$app.openChatButton);
                if(this.$app.openChatButton) {
                    console.log('Open chat');
                    this.$app.sendToUnity('SetChatActive', '0');
                } 
            },
            checkFocus() {
                if (!this.elements.chat.expanded && !this.elements.participants.expanded) {
                    focusUnity(this.checkMobile);
                }
            },
            scrollMessages() {
                if (!this.$refs["messages"]) return;
                let messagesElement = this.$refs["messages"].$el;
                this.autoScroll = messagesElement.scrollHeight -
                    messagesElement.offsetHeight -
                    messagesElement.scrollTop <= 50;
            },
            messageCharCount(event) {
                if (event.code === "Enter" || event.code === "NumpadEnter") {
                    event.preventDefault();
                    this.sendMessage();
                } else if (
                    this.$refs.txtmessage.textContent.length > this.messageMaxLenght &&
                    event.code !== "Backspace" &&
                    event.code !== "ArrowRight" &&
                    event.code !== "ArrowLeft" &&
                    event.code !== "ArrowUp" &&
                    event.code !== "ArrowDown" &&
                    event.code !== "Delete"
                ) {
                    event.preventDefault();
                }
            },
            mousedown(e) {
                let component = this.elements.participants;
                let div = this.$refs["participants-box"];
                component.isDown = true;
                component.offset = [
                    div.offsetLeft - e.clientX,
                    div.offsetTop - e.clientY,
                ];
            },
            mouseup() {
                this.elements.participants.isDown = false;
            },
            mousemove(event) {
                event.preventDefault();
                let component = this.elements.participants;
                if (component.isDown) {
                    const mousePosition = {
                        x: event.clientX,
                        y: event.clientY,
                    };
                    let div = this.$refs["participants-box"];
                    div.style.left = mousePosition.x + component.offset[0] + "px";
                    div.style.top = mousePosition.y + component.offset[1] + "px";
                }
            },
            mousedownChat(e) {
                let component = this.elements.chat;
                let div = this.$refs["chat-box"];
                component.isDown = true;
                component.offset = [
                    div.offsetLeft - e.clientX,
                    div.offsetTop - e.clientY,
                ];
            },
            mouseupChat() {
                this.elements.chat.isDown = false;
            },
            mousemoveChat(event) {
                // event.preventDefault();
                let component = this.elements.chat;
                if (component.isDown) {
                    const mousePosition = {
                        x: event.clientX,
                        y: event.clientY,
                    };
                    let div = this.$refs["chat-box"];
                    div.style.left = mousePosition.x + component.offset[0] + "px";
                    div.style.top = mousePosition.y + component.offset[1] + "px";
                }
            },
            mousedownBox(e) {
                if (this.boxDragAndDrop) {
                    let component = this.elements.box;
                    let div = this.$refs["box"];
                    component.isDown = true;
                    //console.log(div);
                    component.offset = [
                        div.offsetLeft - e.clientX,
                        div.offsetTop - e.clientY,
                    ];
                }
            },
            mouseupBox() {
                if (this.boxDragAndDrop) {
                    this.elements.box.isDown = false;
                }
            },
            mousemoveBox(event) {
                if (this.boxDragAndDrop) {
                    event.preventDefault();
                    let component = this.elements.box;
                    if (component.isDown) {
                        const mousePosition = {
                            x: event.clientX,
                            y: event.clientY,
                        };
                        let div = this.$refs["box"];
                        div.style.left = mousePosition.x + component.offset[0] + "px";
                        div.style.bottom = mousePosition.y + component.offset[1] + "px";
                    }
                }
            },
            setMessageMaxLenght(max) {
                this.messageMaxLenght = max - 1;
            },
            setBoxPosition(position) {
                this.elements.box.position = position;
            },
            setBoxDimension(width, height) {
                this.elements.box.width = width;
                this.elements.box.height = height;
            },
            setBoxDragAndDrop(active) {
                this.boxDragAndDrop = active;
            },
            setParticipantsVisible(visible) {
                this.participantsVisible = visible;
            },
            setChatDimension(width, height) {
                this.elements.chat.width = width;
                this.elements.chat.height = height;
            },
            setChatPosition(position) {
                this.elements.chat.position = position;
            },
            setParticipantsPosition(position) {
                this.elements.participants.position = position;
            },
            setParticipantsDimension(width, height) {
                this.elements.participants.width = width;
                this.elements.participants.height = height;
            },
            setChatExpanded(expanded) {
                this.elements.chat.expanded = expanded;
            },
            setParticipantsExpanded(expanded) {
                this.elements.participants.expanded = expanded;
            },
            addEmoji(value) {
                //if (this.textMessage) this.textMessage += `<i class="${value}" aria-role="presentation" aria-label="BIRD"></i>`;
                //else this.textMessage = `<i class="${value}" aria-role="presentation" aria-label="BIRD"></i>`;
                this.showEmijiBox = false;
                //console.log(this.$refs);
                this.$refs.txtmessage.innerHTML +=
                    "<div style='display:inline'>" + value + "</div>&nbsp;";
                //this.focusEnd(this.$refs.txtmessage);
                //this.$refs.txtmessage.selectionStart = this.$refs.txtmessage.selectionEnd = this.$refs.txtmessage.value.length;
            },
            toggleHasVolume(event) {
                event.stopPropagation();
                this.hasVolume = !this.hasVolume;
                for (let stream of Object.values(this.usersStreams)) {
                    stream.getAudioTracks()[0].enabled = this.hasVolume;
                }
            },
            toggleCanSpeak(event) {
                event.stopPropagation();
                this.canSpeak = !this.canSpeak;
                this.media.getAudioTracks()[0].enabled = this.canSpeak;
            },
            setLocale(locale) {
                if (locale === "es") {
                    this.localeMessages = {
                        chat: "CHAT",
                        participantes: "PARTICIPANTES",
                        msg1: "Encienda o apague su micrófono haciendo clic en el icono",
                        msg2: "Enciende o apaga el sonido del chat con el botón de volumen",
                    };
                }
                if (locale === "en") {
                    this.localeMessages = {
                        chat: "CHAT",
                        participantes: "PARTICIPANTS",
                        msg1: "Turn your microphone on or off by clicking on the icon",
                        msg2: "Turn the chat sound on or off using the volume button",
                    };
                }
            },
            setServerPeer() {
                //this.peerServerConfig.host = `peerjs-${baseUrl}`;
                const baseUrl = this.switch.url_chat.replace("https://","").replace("chat","").replace("/","");
                this.peerServerConfig.host = `peerjs${baseUrl}`;
            },
            userConnected({ userId, userName, peerId}) {
                let self = this;
                //console.log("User connected", userId, userName, peerId);
                this.users.push({
                    id: userId,
                    name: userName,
                    status: "on-line",
                    peerId,
                });
                if (this.roomHasAudioStream) {
                    //console.log("Call", peerId, this.media);
                    setTimeout(() => {
                        let call = this.peer.call(peerId, this.media);
                        if (call) {
                            call.on("stream", function (stream) {
                                //console.log("Call answerer", stream);
                                // check if you can speak
                                self._createAudioElement({ muted: false, stream, userId });
                            });
                        }
                    }, 1000);
                }
            },
            userDisconnected(userId) {
                //console.log("User disconnected", userId);
                let users = this.users;
                let index = users.findIndex((user) => user.id === userId);
                if (index >= 0) {
                    if (users[index].audioElement) users[index].audioElement.remove();
                    if (this.usersStreams[userId]) delete this.usersStreams[userId];
                    users.splice(index, 1);
                }
            },
            checkMessageSimilarity(message) {
                let timeSlot = 60 * 1000;
                for (let messageItem of this.sentMessages) {
                    let timeOffset = new Date().getTime() - messageItem.time;
                    if (timeOffset < timeSlot) {
                        let similarity = stringSimilarity.compareTwoStrings(
                            messageItem.message.toLowerCase(),
                            message.toLowerCase()
                        );
                        if (similarity > 0.85) {
                            messageItem.count++;
                            messageItem.time = new Date().getTime();
                            return messageItem.count < 5;
                        }
                    }
                }
                let clearedSentMessages = [];
                let now = new Date().getTime();
                for (let messageItem of this.sentMessages) {
                    if (now - messageItem.time <= timeSlot)
                        clearedSentMessages.push(messageItem);
                }
                clearedSentMessages.push({
                    message: this.textMessage,
                    time: new Date().getTime(),
                    count: 1,
                });
                this.sentMessages = clearedSentMessages;
                return true;
            },
            sendMessage() {
                this.textMessage = this.$refs.txtmessage.innerHTML;
                if (this.textMessage && this.checkMessageSimilarity(this.textMessage)) {
                    this.socket.emit("sendMessage", this.textMessage);
                    this.textMessage = null;
                    this.$refs.txtmessage.innerHTML = "";
                    this.$refs.txtmessage.focus();
                }
            },
            _createAudioElement({ muted = false, stream, userId }) {
                //console.log("Visual element", stream);
                this.usersStreams[userId] = stream;
                const audio = document.createElement("audio");
                audio.muted = muted;
                audio.srcObject = stream;

                audio.addEventListener("loadedmetadata", async () => {
                    try {
                        await audio.play();
                    } catch (error) {
                        console.error("erro to play", error);
                    }
                });

                for (let user of this.users) {
                    if (user.id === userId) user.audioElement = audio;
                }
            },
            setupPeer() {
                let self = this;
                return new Promise((resolve) => {

                    self.peer = new Peer(undefined, self.peerServerConfig);
                    self.peer.on("open", (stream) => {
                        stream
                        //console.log("PeerOpen");
                        resolve(self.peer);
                    });
                    self.peer.on("call", function (call) {
                        let peerId = call.peer;
                        let userId;
                        for (let user of self.users) {
                            if (user.peerId === peerId) userId = user.id;
                        }
                        //console.log("On call", call, userId);
                        // Answer the call, providing our mediaStream
                        call.answer(self.media);
                        call.on("stream", function (stream) {
                            //console.log("On Stream answerer");
                            //check if has volume
                            self._createAudioElement({ muted: false, stream, userId });
                        });
                    });
                });
            },
            getUserMedia() {
                return new Promise((resolve, reject) => {
                    navigator.mediaDevices
                        .getUserMedia({
                            audio: true,
                        })
                        .then((media) => {
                            resolve(media);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },
            async joinRoom(room, roomUsersLimit, hasAudioStream, autoModerate) {
                this.currentRoom = {
                    room,
                    roomUsersLimit,
                    hasAudioStream,
                    autoModerate,
                };
                let self = this;
                this.roomHasAudioStream = hasAudioStream;
                this.room = room;
                if (hasAudioStream) {
                    this.messages.push({
                        name: "---",
                        message: this.localeMessages.msg1,
                    });
                    this.messages.push({
                        name: "---",
                        message: this.localeMessages.msg2,
                    });
                    //TODO desconectar todas chamadas anteriores
                    if (this.peer) {
                        this.peer.destroy();
                        this.peer = null;
                    }
                    if (!this.peer) {
                        await this.setupPeer();
                        try {
                            let devices = await navigator.mediaDevices.enumerateDevices();
                            devices;
                            if (this.checkMobile) {
                                this.$app.modal('success', "Sala com Áudio", "Esta sala utiliza áudio, vamos habilitar seu microfone!", () => {
                                    navigator.mediaDevices
                                        .getUserMedia({
                                            audio: { echoCancellation: true },
                                            video: false,
                                        })
                                        .then((media) => {
                                            self.media = media;
                                            this.canSpeak = false;
                                            this.media.getAudioTracks()[0].enabled = this.canSpeak;
                                            //console.log("media", media);
                                        })
                                        .catch((error) => {
                                            self.roomHasAudioStream = false;
                                            console.log(error);
                                        });
                                }, null, null, "OK");
                            } else {
                                navigator.mediaDevices
                                    .getUserMedia({
                                        audio: { echoCancellation: true },
                                        video: false,
                                    })
                                    .then((media) => {
                                        self.media = media;
                                        this.canSpeak = false;
                                        this.media.getAudioTracks()[0].enabled = this.canSpeak;
                                        //console.log("media", media);
                                    })
                                    .catch((error) => {
                                        self.roomHasAudioStream = false;
                                        console.log(error);
                                    });
                            }
                        } catch (error) {
                            self.roomHasAudioStream = false;
                            console.log(error);
                        }
                    }
                }
                if (!this.socket) this.connectServer();
                this.socket.emit("joinRoom", {
                    userId: this.id,
                    userName: this.nickname,
                    room,
                    roomUsersLimit,
                    hasAudioStream,
                    autoModerate,
                    peerId: this.peer ? this.peer.id : null,
                    sessionId: this.current_session.id??null,
                    isBanned: this.isBanned,
                });
            },
            updateUserList(userList) {
                //console.log("USER list", userList);
                this.users = userList;
                for (let user of this.users) {
                    if (user.id === this.id) {
                        this.isBanned = user.isBanned;
                    }
                }
            },
            onMessage({ userId, message }) {
                for (let user of this.users) {
                    if (user.id === userId && !user.isBanned) {
                        if (this.messages.length > this.messagesLimit)
                            this.messages.shift();
                        if (message){
                            message = message.split("<div style=\"display:inline\"><i cl*****").join("<div style=\"display:inline\"><i class")
                        }
                        this.messages.push({ name: user.name, message });
                        if (this.elements.chat.expanded) this.unreadMessagesCount = 0;
                        else this.unreadMessagesCount++;
                        if (this.autoScroll) this.scroll();
                    }
                }
            },
            scroll() {
                setTimeout(() => {
                    if (!this.$refs["messages"]) return;
                    let messagesElement = this.$refs["messages"].$el;
                    //let deltaScroll = messagesElement.scrollHeight - messagesElement.offsetHeight - messagesElement.scrollTop;
                    //if (deltaScroll < 100) {
                    messagesElement.scroll({
                        top: messagesElement.scrollHeight + 50,
                        behavior: "auto",
                    });
                    //}
                }, 500);
            },
            onNotify(payload) {
                payload = JSON.parse(payload);
                const _root_ = this;
                switch (payload.type){
                    /*
                           system
                           vip_room_request
                           friend_request
                           easter_egg
                           admin
                           */
                    case "system":
                        _root_.$app.notifications_count++;
                        _root_.$app.update_notifications(payload);
                        _root_.$app.updatePopupNotifications();
                        break;
                    case "friend_request":
                        _root_.$app.notifications_count++;
                        _root_.$app.update_notifications(payload);
                        _root_.$app.updatePopupNotifications();
                        if (_root_.$route.name === "Friends") {
                            //this.$refs['main'].loadRequest();
                            let data = {
                                avatar: payload.icon,
                                friend: false,
                                id: payload.friend_id,
                                name: payload.nickname,
                                nickname: payload.nickname,
                                requested: true,
                            };
                            _root_.$app.$refs["main"].total_request++;
                            _root_.$app.$refs["main"].list_request.push(data);
                        }
                        if (_root_.$route.name === "Iframe")
                            _root_.addRelatedFriend(Number(payload.friend_id));
                        break;
                    case "vip_room_request":
                        _root_.$app.notifications_count++;
                        _root_.$app.update_notifications(payload);
                        _root_.$app.updatePopupNotifications();
                        break;
                    case "easter_egg":
                    case "max_points":
                        _root_.$app.notifications_count++;
                        _root_.$app.update_notifications(payload);
                        _root_.$app.updatePopupNotifications()
                        break;
                    case "private_message":
                        // se estiver como o modal fechado, ou se estiver com o modal aberto e diferente do friend
                        if( !this.$app.modal_private_messages || (this.$app.modal_private_messages && this.$app.private_messages_friend_id != payload.friend_id) ) {
                            this.$app.notifications_count++;
                            this.$app.update_notifications_private_message(payload);
                            this.$app.updatePopupNotifications();
                        }
                        break;
                    case "notify_points":
                        //this.$app.count_notify_points++;
                        //console.log('COUNT =>', this.$app.count_notify_points);
                        _root_.$app.notifications_count++;
                        _root_.$app.update_notifications_notify_points(payload);
                        _root_.$app.updatePopupNotifications();
                        /*if(this.$app.count_notify_points == 2) {
                            this.$app.count_notify_points = 0;
                            //this.onNotify(JSON.stringify(payload));
                            console.log('FLUXO NOTIFY POINT 4');
                            _root_.$app.notifications_count++;
                            _root_.$app.update_notifications_notify_points(payload);
                            _root_.$app.updatePopupNotifications();
                        }*/
                        break;
                    case "admin":
                        //console.log'admin message => ', payload);
                        switch (payload.subtype) {
                            case "check_tab":
                                var dat__ = new Date(payload.dat);
                                if (_root_.$app.age_get_user === false) {
                                    if (
                                        String(_root_.$app.dat) !== "null" &&
                                        String(dat__) !== String(_root_.$app.dat)
                                    ) {
                                        _root_.$app.modal(
                                            "error",
                                            this.$i18n.t('app.multiplasJanelas'),
                                            this.$i18n.t('app.voceFoiDesconectado'),
                                            null,
                                            null,
                                            null,
                                            "OK"
                                        );
                                        _root_.$app.logoutEnd();
                                    }
                                }
                                if (this.age_get_user === true) {
                                    this.age_get_user = false;
                                }
                                break;
                            case "badge_earned":
                                _root_.$app.badge.file_url = payload.file_url
                                _root_.$app.badge.text = this.$i18n.t(payload.text) + payload.name
                                _root_.$app.badge.name = payload.name
                                _root_.$app.badge.external_url = payload.external_url
                                _root_.$app.modal_badge_ = true
                                var newTotalBadges = Number(_root_.$app.TotalBadges) + 1;
                                _root_.$app.update_totalbadges(newTotalBadges);
                                break;
                            case "check_ticket":
                                //ticket_id
                                if (_root_.$app.UserTickets.length > 0) {
                                    for (let o in _root_.$app.UserTickets) {
                                        if (
                                            Number(payload.ticket_id) ==
                                            Number(_root_.$app.UserTickets[o].id) &&
                                            _root_.$app.UserTickets[o].payment_method == "convite"
                                        ) {
                                            _root_.$app.update_user_tickets_del(o);
                                            break;
                                        }
                                    }
                                }

                                if (
                                    _root_.$app.$route.name == "BarVip" ||
                                    _root_.$app.$route.name == "BudBarVip"
                                ) {
                                    _root_.$app.chat_room = "";
                                    _root_.$app.chat_owner = "";
                                    _root_.$app.modal(
                                        "status",
                                        this.$i18n.t('app.atencao'),
                                        this.$i18n.t('app.voceFoiDesconvidado'),
                                        null,
                                        null,
                                        null,
                                        "OK"
                                    );
                                    _root_.$app.link("Ticket");
                                }
                                break;
                            case "point_coin_update":
                                if (Number(this.TotalCoins) != Number(payload.coins)) {
                                    if (payload.coins !== "-1") {
                                        _root_.$app.updateUser("TotalCoins", payload.coins);
                                        _root_.$app.update_coin(payload.coins);
                                    }
                                }
                                if (Number(this.TotalPoints) != Number(payload.points)) {
                                    if (payload.points !== "-1") {
                                        _root_.$app.updateUser("TotalPoints", payload.points);
                                        _root_.$app.update_point(payload.points);
                                    }
                                }
                                break;
                            case "event_update":
                                for (let o in this.EventimTickets) {
                                    if (
                                        String(this.EventimTickets[o].id) == String(payload.id)
                                    ) {
                                        _root_.$app.EventimTickets[o].after_event_description =
                                            payload.after_event_description;
                                        _root_.$app.EventimTickets[o].after_event_url =
                                            payload.after_event_url;
                                        _root_.$app.EventimTickets[o].coin_price = payload.coin_price;
                                        _root_.$app.EventimTickets[o].date_fin = payload.date_fin;
                                        _root_.$app.EventimTickets[o].date_ini = payload.date_ini;
                                        _root_.$app.EventimTickets[o].event_date = payload.event_date;
                                        _root_.$app.EventimTickets[o].event_time = payload.event_time;
                                        _root_.$app.EventimTickets[o].id_eventim = payload.id_eventim;
                                        _root_.$app.EventimTickets[o].link_eventim =
                                            payload.link_eventim;
                                        _root_.$app.EventimTickets[o].link_vimeo =
                                            payload.after_event_description;
                                        _root_.$app.EventimTickets[o].local = payload.local;
                                        _root_.$app.EventimTickets[o].name = payload.name;
                                        _root_.$app.EventimTickets[o].price = payload.price;
                                        _root_.$app.EventimTickets[o].state = payload.state;
                                        break;
                                    }
                                }

                                if (payload.state == "Ao vivo") {
                                    _root_.$app.live = true;
                                    if (
                                        // this.isWatchingRoom() &&
                                        !_root_.$app.$refs["ticket-gate"].enterCheck("")
                                    ) {
                                        _root_.$app.modal(
                                            "status",
                                            this.$i18n.t('app.ingressos'),
                                            this.$i18n.t('app.oEventoJaComecou'),
                                            null,
                                            null,
                                            null,
                                            "OK"
                                        );

                                        if (_root_.$app.$route.name != "Ticket") this.link("Ticket");
                                    }
                                } else {
                                    _root_.$app.live = false;
                                    if (
                                        _root_.$app.$route.name == "Ticket" ||
                                        _root_.$app.$route.name == "TicketPurchased"
                                    )
                                        window.location.reload();
                                    /*
                                                    this.modal('status', 'INGRESSOS', "O administrador mudou informações sobre o evento e você foi redirecionado para o mapa.", null, null, null, "OK");

                                                    if (this.$route.name != 'Map')
                                                        this.link('Map');
                                                    */
                                }
                                break;
                            case "go_to":
                                //this.link(payload.ambient);
                                //setTimeout(myGreeting, 5000)
                                var _tempo_ = Math.ceil((Math.random() * 60) * 1000);
                                setTimeout(function () { console.log('tempo => ', _tempo_); _root_.$router.push({ path:payload.ambient });  }, _tempo_);
                                //this.$router.push({ path:payload.ambient });
                                break;
                            case "update_watching_rooms":
                                /*
                                              case 'TheaterAvatar':
                                              //
                                              break;
                                              case 'BudBar':
                                                  //
                                              break;
                                              case 'BudBarVip':
                                                  //
                                              break;
                                              case 'StadiumAvatarGame':
                                                  //
                                              break;
                                              case 'StadiumAvatarShow':
                                                  //
                                              break;
                                              */
                                if (
                                    this.$route.name == "Bar" ||
                                    this.$route.name == "BarVip" ||
                                    this.$route.name == "BudBar" ||
                                    this.$route.name == "BudBarVip" ||
                                    this.$route.name == "Theater" ||
                                    this.$route.name == "TheaterAvatar" ||
                                    this.$route.name === "Stadium" ||
                                    this.$route.name === "StadiumGame" ||
                                    this.$route.name === "StadiumAvatarGame" ||
                                    this.$route.name === "StadiumAvatarGameUnderage" ||
                                    this.$route.name === "StadiumAvatarShow" ||
                                    this.$route.name === "StadiumUnderage" ||
                                    this.$route.name === "StadiumGameUnderage" ||
                                    this.$route.name === "Store"
                                ) {
                                    //window.location.reload();

                                    for (let o = 0; o < _root_.$app.sessions.length; o++) {
                                        if (
                                            (this.$route.name === "Theater" ||
                                                this.$route.name == "TheaterAvatar") &&
                                            _root_.$app.sessions[o].url ===
                                            _root_.$app.$url_api + "5gd2zb/e/NBA-HOUSE-MAR2021/NBA-THEATER"
                                        ) {
                                            if (
                                                String(_root_.$app.sessions[o].template_id) ===
                                                String(payload.id_template)
                                            ) {
                                                window.location.reload();
                                            }
                                            break;
                                        } else if (
                                            (this.$route.name === "Bar" ||
                                                this.$route.name === "BudBar") &&
                                            _root_.$app.sessions[o].url ===
                                            this.$url_api + "5gd2zb/e/NBA-HOUSE-MAR2021/NBA-BAR"
                                        ) {
                                            if (
                                                String(_root_.$app.sessions[o].template_id) ===
                                                String(payload.id_template)
                                            ) {
                                                window.location.reload();
                                            }
                                            break;
                                        } else if (
                                            (this.$route.name == "BarVip" ||
                                                this.$route.name == "BudBarVip") &&
                                            _root_.$app.sessions[o].url ==
                                            _root_.$app.$url_api + "5gd2zb/e/NBA-HOUSE-MAR2021/BUD-BAR-VIP"
                                        ) {
                                            if (
                                                String(_root_.$app.sessions[o].template_id) ==
                                                String(payload.id_template)
                                            ) {
                                                window.location.reload();
                                            }
                                            break;
                                        } else if (
                                            (this.$route.name == "StadiumGame" ||
                                                this.$route.name == "StadiumGameUnderage" ||
                                                this.$route.name == "StadiumAvatarGame") &&
                                            _root_.$app.sessions[o].url ==
                                            _root_.$app.$url_api + "5gd2zb/e/NBA-HOUSE-MAR2021/NBA-STADIUM"
                                        ) {
                                            if (
                                                String(this.sessions[o].template_id) ==
                                                String(payload.id_template)
                                            ) {
                                                window.location.reload();
                                            }
                                            break;
                                        } else if (
                                            (this.$route.name == "Stadium" ||
                                                this.$route.name == "StadiumUnderage" ||
                                                this.$route.name == "StadiumAvatarShow") &&
                                            _root_.$app.sessions[o].url ==
                                            _root_.$app.$url_api +
                                            "5gd2zb/e/NBA-HOUSE-MAR2021/NBA-STADIUM---SHOW"
                                        ) {
                                            if (
                                                String(_root_.$app.sessions[o].template_id) ==
                                                String(payload.id_template)
                                            ) {
                                                window.location.reload();
                                            }
                                            break;
                                        }
                                    }
                                }
                                break;
                            case "group_update":
                                //console.log'group_update => ', payload);
                                var groups_ = [];
                                for (let o in _root_.$app.groups) {
                                    if (String(_root_.$app.groups[o].id) == String(payload.id_group)) {
                                        let dat_group = {
                                            id: payload.id_group,
                                            on: payload.on,
                                            id_room: payload.id_room,
                                            name: _root_.$app.groups[o].name
                                        };
                                        groups_.push(dat_group);
                                    } else {
                                        groups_.push(_root_.$app.groups[o]);
                                    }
                                }
                                _root_.$app.update_groups(groups_);
                                // _root_.$app.groupHandler();
                                break;
                            //atualização sessão adm
                            case "session_update":
                                var sessions_ = [];
                                var dat_;
                                for (let o = 0; o < _root_.sessions.length; o++) {
                                    if (String(_root_.sessions[o].id) == String(payload.session.id)) {
                                        dat_ = payload.session;
                                        if (_root_.current_session.id === dat_.id){
                                            _root_.update_current_session(dat_);
                                            _root_.update_id_form_actual(dat_.form_id)
                                            _root_.$app.sessionHandler(dat_,_root_.interactive !== 'admin');
                                            if(_root_.$app.modal_iframe){
                                                _root_.$app.modal_iframe = false;
                                                let _root = this;
                                                if (dat_.quiz !==0) {
                                                    setTimeout(() => {
                                                        _root.modal_iframe = true;
                                                    }, 50)
                                                }
                                            }
                                        }
                                        sessions_.push(dat_);
                                    } else {
                                        sessions_.push(_root_.sessions[o]);
                                    }
                                }
                                _root_.update_sessions(sessions_);
                                break;
                            case "switch":
                                switch (this.$route.name) {
                                    case "Iframe":
                                        _root_.$app.$refs["ambient_transition"].quiz =
                                            String(payload.switch.quiz) == "true";
                                        break;
                                }
                                _root_.update_switch(payload.switch);
                                break;
                            case "logout":
                                // var now = new Date();
                                // var past = _root_.$app.login_date;
                                // var diff = Math.abs(now.getTime() - past.getTime());
                                // if (diff > 5) {
                                    _root_.$app.modal(
                                        "error",
                                        this.$i18n.t('app.loginsMultiplos'),
                                        this.$i18n.t('app.voceFoiDesconectadoPois'),
                                        null,
                                        null,
                                        null,
                                        "OK"
                                    );
                                    _root_.$app.logoutEnd();
                                // }
                                break;
                            case "private_message": 

                                var headers     = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization: `Bearer ${this.token}` };
                                var form_data   = new FormData();
                                form_data.append('user', payload.user.id);
                                form_data.append('friend', payload.friend.id);
                                form_data.append('user_notification', payload.user_notification.id);

                                // se o modal esta aberto e conversando com o respectivo amigo, cria a mensagem e atualiza o status da notificacao para reed
                                if( this.$app.modal_private_messages && this.$app.private_messages_friend_id == payload.user.id ) {
                                    this.$app.$refs.pm_messages.createMessageElement(payload.message);
                                    // form_data.append('user_notification_flag', 'reed');  // já é reed como padrão
                                }
                                // se nao tiver, atualiza o status da notificacao (somente para disparar o websocket pois já é criada como pendent)
                                else {
                                    form_data.append('user_notification_flag', 'pendent');
                                    // atualiza o total de unread -- todo
                                    if (_root_.$route.name === "Friends") {
                                        let idU = payload.user.id;
                                        if (idU === this.id){
                                            idU = payload.friend.id;
                                        }
                                        // console.log('ESTAMOS NA ROTA AMIGOS NO CHAT (websocket)');
                                        // console.log('PAYLOAD USER_ID ', payload.user.id);
                                        // console.log('OFFSET ', this.$app.$refs.main.offset);
                                        let index = Object.keys(this.$app.$refs.main.privateMessagesUsers).find(
                                            x => this.$app.$refs.main.privateMessagesUsers[x].inbox.from_user === idU
                                            || this.$app.$refs.main.privateMessagesUsers[x].inbox.to_user === idU
                                        );
                                        if( this.$app.$refs.main.privateMessagesUsers[index] ) {
                                            this.$app.$refs.main.privateMessagesUsers[index].inbox.unread = this.$app.$refs.main.privateMessagesUsers[index].inbox.unread + 1;
                                            this.$app.$refs.main.privateMessagesUsers[index].inbox.last_message = payload.message.message;
                                            // this.$app.$refs.main.offset = this.$app.$refs.main.offset + 1;
                                            // console.log('ESSE!', this.$app.$refs.main.privateMessagesUsers[index]);
                                        } else {
                                            // tem que criar um no topo da lista e dar o offset + 1
                                            let new_row     = {
                                                'ambient'   : '',
                                                'avatar'    : 'https://ui-avatars.com/api/?name='+payload.user.nickname+'&color=33bcee&background=e3e8e7',
                                                'friend'    : false,
                                                'id'        : payload.user.id,
                                                'name'      : payload.user.name,
                                                'nickname'  : payload.user.nickname,
                                                'requested' : false,
                                                'inbox'     : {
                                                    'from_user'         : payload.user.id,
                                                    'last_message'      : payload.message.message,
                                                    'last_message_date' : '',
                                                    'to_user'           : payload.friend.id,
                                                    'unread'            : 1
                                                }
                                            };
                                            this.$app.$refs.main.privateMessagesUsers.unshift(new_row);
                                            this.$app.$refs.main.offset = this.$app.$refs.main.offset + 1;
                                        }
                                        // console.log(this.$app.$refs.main.privateMessagesUsers[1]);
                                    }
                                }
                                axios.post(this.$url_api + "api/pm/notification/update", form_data, { headers: headers })
                                    .then((response) => this.loadUpdateNotificationResponse(response))
                                    .catch((error) => this.loadUpdateNotificationError(error.response));

                                break;
                            case "transcription_message":
                                emitter.emit('chat::admin::transcription_message', payload);
                                break;
                        }
                        break;
                        case "cart_order":
                            console.log('cart_order');
                            // var vm = this;
                            // setTimeout(() => {
                                // vm.$app.getNotifications(false);
                            // }, 5000);
                        break;
                }
            },
            loadUpdateNotificationResponse(obj) {
                obj;
                // console.log(obj.data.user_notification);
            },
            loadUpdateNotificationError(obj) {
                console.log('loadUpdateNotificationError => ', obj);
            },
            onBroadcast(message) {
                this.messages.push({ name: "", message });
                if (this.autoScroll) this.scroll();
            },
            disconnected() {
                this.connected = false;
                //this.messages = [];
                this.users = [];
                // this.$q
                // 	.dialog({
                // 		title: "Desconectado!",
                // 		message: "Sua comunicação caiu! Verifique sua conexão com a internet e entre na sala novamente.",
                // 	})
                // 	.onOk(() => {});
            },
            connect() {
                //console.log("Connected", this.connected);
                if (!this.connected) {
                    this.joinRoom(
                        this.currentRoom.room,
                        this.currentRoom.roomUsersLimit,
                        this.currentRoom.hasAudioStream,
                        this.currentRoom.autoModerate
                    );
                    this.connected = true;
                }
            },
            fullRoom() {
                this.$app.modal('error', "Sala cheia!", "Está sala já está com seu limite de usuários!", () => {});
            },
            connectServer() {
                if (!this.socket) {
                    this.connected = true;
                    //console.log("Connecting...");
                    this.socket = io(this.switch.url_chat, {
                        transports: ["websocket"],
                        autoConnect: true,
                        reconnection: true,
                    });
                    this.socket.on("userConnected", this.userConnected);
                    this.socket.on("allowChat", this.allowChat);
                    this.socket.on("userDisconnected", this.userDisconnected);
                    this.socket.on("updateUserList", this.updateUserList);
                    this.socket.on("message", this.onMessage);
                    this.socket.on("notify", this.onNotify);
                    this.socket.on("broadcast", this.onBroadcast);
                    this.socket.on("fullRoom", this.fullRoom);
                    this.socket.on("disconnect", this.disconnected);
                    this.socket.emit("identify", {
                        userId: this.id,
                        userName: this.nickname,
                        jwt: this.token,
                        type: this.interactive === 'admin' ? 'admin' : 'user',
                    });
                    this.socket.on("connect", this.connect);
                }
            },
            allowChat(allow){
                  if (this.isLive){
                      this.allow_chat = allow == "1";
                  }
            },
            setCSS() {
                var css_text = `
                .q-dialog__backdrop {
                  background: none !important;
                }
                `;
                var css = document.createElement("style");
                css.type = "text/css";
                css.appendChild(document.createTextNode(css_text));
                document.body.appendChild(css);
            },
            addRelatedFriend(id){
                if (typeof id == "string"){
                    this.allRelatedFriends.push(Number(id));
                }else if (typeof id ==  "object"){
                    for (const idKey in id) {
                        this.allRelatedFriends.push(Number(id[idKey]));
                    }
                }else{
                    this.allRelatedFriends.push(id);
                }
            },
            openPrivateMessage(friend_id) {
                this.$app.modal_private_messages = true;
                this.$app.private_messages_friend_id = Number(friend_id);
            }
        },
        created() {
            //console.log("Created");
            this.sendMessage = throttle(this.sendMessage, 1500);
            this.setCSS();
        },
        beforeDestroy() {
            console.log('chat destroying');
            if (this.socket)
                this.socket.disconnect();
        },
    }
</script>

<style scoped>
    #chat-iframe
    {
        background: none;
        bottom: 0;
        height: auto;
        position:absolute;
        right: 0;
        width: 450px;
        z-index: 0;
    }

    #chat-admin
    {
        pointer-events: none;
        height: 0;
        width: 0;
    }
    .close-chat-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -25px;
        right: 50px;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        background-color: var(--chat_bgheader_color);
        color: var(--chat_bgheader_text_color);
    }
    @media screen and (max-width: 500px)
    {
        #chat-iframe
        {
            left: 5px;
            width: calc(100% - 10px) !important;
        }
    }
    .add_quasar .q-focus-helper, .add_quasar .q-focusable, .add_quasar .q-hoverable, .add_quasar .q-manual-focusable {
        width: 0;
    }
</style>