<template>
    <div class="box" :class="classRoot" :style="styleRoot">
        <div :class="{'box-content':true,}" id="video-container" :style="styleContent">
            <h3 class="box-tab">
                <div id="box-title" ref="box-title">vídeo</div>
                <a href="#" v-on:click="openModalFS" id="link_full_screen">
                    <svg xmlns="http://www.w3.org/2000/svg" v-show="!modalFs"
                         style="position: absolute;width: 30px;height: 30px;right: 60px;top:13px" height="48"
                         viewBox="0 96 960 960" width="48">
                        <path
                            d="M200 856V663h60v133h133v60H200Zm0-367V296h193v60H260v133h-60Zm367 367v-60h133V663h60v193H567Zm133-367V356H567v-60h193v193h-60Z"
                            fill="#FFF"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" v-show="modalFs"
                         style="position: absolute;width: 30px;height: 30px;right: 60px;top:13px" height="48"
                         viewBox="0 96 960 960" width="48">
                        <path
                            d="M333 856V723H200v-60h193v193h-60Zm234 0V663h193v60H627v133h-60ZM200 489v-60h133V296h60v193H200Zm367 0V296h60v133h133v60H567Z"
                            fill="#FFF"/>
                    </svg>
                </a>
                <a class="fr" ref="btn-close" v-on:click="close">
                    <img :src="$url + 'assets/icons/header/x_1.png'" alt="Fechar"
                         style="filter: brightness(0) invert(1);">
                </a>
            </h3>
            <iframe v-if="url" id="video" ref="video" :src="url" frameborder="0"
                    allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;fullscreen; picture-in-picture;web-share;"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {focusUnity} from "@/helper";

export default {
    name: 'Video',
    props: ['video_url', 'game_endpoint'],
    computed: {
        ...mapGetters([
            'id', 'token', 'logged', 'checkMobile'
        ]),
        classRoot() {
            let c = {};
            if (!this.checkMobile) {
                if (this.$app.currentHash?.modal_type && this.$app.currentHash?.modal_type != 'default') {
                    let type = this.$app.currentHash.modal_type;
                    switch (type) {
                        case '2_3':
                        case '1_3':
                        case '1_2':
                            type = "ratio" + type;
                    }
                    c[type] = true;
                }
                if (this.$app.currentHash?.modal_position && this.$app.currentHash?.modal_position != 'default') {
                    c[this.$app.currentHash.modal_position] = true;
                }
            }
            c['video-full'] = this.modalFs;
            return c;
        },
        styleRoot() {
            let style = {};
            if (!this.checkMobile) {
                if (this.$app.currentHash?.modal_position && this.$app.currentHash?.modal_position != 'default') {
                    if (!this.modalFs) {
                        if (this.$app.currentHash?.modal_width_min > 0) {
                            style['min-width'] = "min(100%," + this.$app.currentHash?.modal_width_min + "px)";
                        }
                        if (this.$app.currentHash?.modal_width_max > 0) {
                            style['max-width'] = "min(100%," + this.$app.currentHash?.modal_width_max + "px)";
                        }
                    }
                }
            }
            return style;
        },
        styleContent() {
            let style = {};
            if (!this.checkMobile) {
                if (this.$app.currentHash?.modal_position == 'default') {
                    if (!this.modalFs) {
                        if (this.$app.currentHash?.modal_width_min > 0) {
                            style['min-width'] = "min(100%," + this.$app.currentHash?.modal_width_min + "px)";
                        }
                        if (this.$app.currentHash?.modal_width_max > 0) {
                            style['max-width'] = "min(100%," + this.$app.currentHash?.modal_width_max + "px)";
                        }
                    }
                }
            }
            return style;
        },
    },
    data() {
        return {
            url: null,
            urlFS: null,
            modalFs: false,
            typeModal: "default",//default|pip|1-2|1-3|2-3
            positionModal: "default",//default|left|right
            minWidth: 0,
            maxWidth: 0,
        }
    },
    methods: {
        ...mapActions([
            "reset_unity_hash_log"
        ]),
        close(e) {
            e;
            this.reset_unity_hash_log()
            focusUnity(this.checkMobile);
            this.$app.currentHash = null;
            this.$app.unity_video = false;
        },
        isVideo(url) {
            return (url.indexOf("youtube") !== -1 || url.indexOf("vimeo") !== -1 ||
                url.indexOf(".mp4") !== -1 || url.indexOf(".avi") !== -1);
        },
        iframeURL() {
            if (this.video_url) {
                if (this.isVideo(this.video_url)) {
                    console.log(this.video_url);
                    this.url = this.$url + 'iframe_video.html?url_api=' + this.$url_api + '&streaming_video_address_1=' + this.video_url;
                    this.$refs['box-title'].innerText = 'vídeo';
                } else {
                    this.url = this.video_url;
                    this.$refs['box-title'].innerText = '';
                    let headers = {
                        "Content-Type": "application/json;charset=UTF-8",
                        Access: "application/json",
                        language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
                    };
                    axios.post(this.$url_api + "api/credit/view_file_hash", {file: this.url}, {headers: headers,})
                        .then(_ => _).catch(_ => _);
                }
            }
        },
        openModalFS(e) {
            e.preventDefault();
            this.modalFs = !this.modalFs;
        }
    },
    mounted() {
        this.iframeURL();
        console.log('VideoMounted');
    }
}
</script>

<style lang="scss" scoped>
/*----- general -----*/
h3 {
    color: white !important;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
}

.fr {
    float: right;
}

.mt-5 {
    margin-top: 5px;
}

/*----- \general\ -----*/

/*----- box -----*/
.box {
    background-color: rgba(0, 0, 0, .7);
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.box::-webkit-scrollbar {
    display: none;
}

.box-content {
    background-color: white;
    border-radius: 4px;
    height: auto;
    position: absolute;
    width: 1000px;
}

.box.left, .box.right {
    background-color: transparent;
    z-index: 0;
    top: var(--headers_height);
    height: calc(100% - var(--headers_height));

    &.ratio2_3 {
        width: 66.66%;
    }

    &.ratio1_3 {
        width: 33.33%;
    }

    &.ratio1_2 {
        width: 50%;
    }

    .box-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        top: 0;
    }
}

.box:not(.left,.right,.video-full) .box-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box.ratio2_3:not(.left,.right) .box-content {
    width: 66.66%;
}

.box.ratio1_3:not(.left,.right) .box-content {
    width: 33.33%;
}

.box.ratio1_2:not(.left,.right) .box-content {
    width: 50%;
}

.box.left {
    left: 0;
}

.box.right {
    left: auto;
    right: 0;
}

.box.left .box-content {
    left: 0;
}

.box.right .box-content {
    left: auto;
    right: 0;
}

.box-tab {
    background-color: var(--buttons_color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-sizing: border-box;
    color: #15b5eb;
    padding: 18px 25px;
}

.box-body {
    box-sizing: border-box;
    max-height: calc(100% - 400px);
    overflow-y: auto;
    padding: 15px 25px;
}

#box-title {
    display: inline-block;
}

/*----- \box\ -----*/


/*----- video -----*/
#video {
    background-color: white;
    height: 100%;
    min-height: 600px;
    width: 100%;
}

/*----- \video\ -----*/

.lightbox-pdf {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999;
}


@media screen and (max-width: 1000px) {
    .box-content {
        border-radius: 0;
        height: 100%;
        left: 0;
        min-height: 100%;
        top: 0;
        width: 100%;
    }
    #link_full_screen {
        display: none;
    }
}

.video-full {
    width: 100% !important;
    top: 0px !important;
    height: 100% !important;
    left: 0px !important;
    z-index: 9999 !important;
}

.video-full #video-container {
    width: 100% !important;
    height: 100% !important;
    top: 0px !important;
    left: 0px !important;
    right: auto !important;
    bottom: auto !important;
}
</style>