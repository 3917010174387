import { render, staticRenderFns } from "./WhiteboardCreateByTemplate.vue?vue&type=template&id=03598a2b&scoped=true&"
import script from "./WhiteboardCreateByTemplate.vue?vue&type=script&lang=js&"
export * from "./WhiteboardCreateByTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03598a2b",
  null
  
)

export default component.exports