<template>

    <div class="modals bgAzul" id="esqueciSenha" ref="esqueciSenha">
        <div class="esqueciSenha">
            <div class="modals__content">
                <div class="modals__content--header alignVertical">
                    <p>{{ $t('rememberpassword.esqueceuSuaSenha') }}</p>
                    <a class="btn-0" v-on:click="$app.link('Login');" ref="btn-close">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <form class="modals__content--body flex flex-wrap" id="remember-password-form" ref="remember-password-form">
                    <p>{{ $t('rememberpassword.informeSeuEmail') }}</p>

                    <div class="col-12 col-sm-7 flex">
                        <input class="col-12 text" id="email" name="email" :placeholder="$t('rememberpassword.seuEmail')" ref="email" type="text">
                    </div>
                    <div v-if="this.show_captcha" class="col-12 flex" style="margin-top: 10px; justify-content: center; margin-left: 10px;">
                        <div class="col-12">
                            <!-- <img class="float-right" src="assets/images/home/capchat.png"> -->
                            <!-- <div id="captchaEsqueci" class="g-recaptcha flex" style="justify-content:center" :data-sitekey="this.switch.captcha_client"></div> -->
                            <vue-recaptcha ref="recaptcha" @render="checkCaptcha = false" @verify="checkCaptcha = true;" @expired="checkCaptcha = false;" :sitekey="this.switch.captcha_client" />
                        </div>
                    </div>
                    <div class="esqueciSenha__button flex col-12 col-sm-5">
                        <input v-if="!checkCaptcha" class="button__buttonForm button__red col-12 button__desactive" disabled="true" ref="btn-submit" :value="btn_submit_value">
                        <input v-else class="button__buttonForm button__red col-12" ref="btn-submit" v-on:click="send" :value="btn_submit_value">
                    </div>
                    <input type="button" class="button__buttonForm btn-simple" v-on:click="$app.link('Login');" :value="$t('rememberpassword.voltar')">
                </form>
            </div>
        </div>
    </div>

    <!-- <div class="box-bg" id="remember-password" ref="remember-password">
        <form class="ani box" id="remember-password-form" ref="remember-password-form" v-on:submit="send">
            <h3 class="tab">{{ $t('rememberpassword.recadastrarSenha') }}<router-link class="btn-0" to="/login" ref="btn-close">
                    <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" style="float: right;margin: 20px 25px 0 0" width="16">
                </router-link>
            </h3>
            <br>
            <ul>
                <li>
                    <br>{{ $t('rememberpassword.informeSeuEmail') }}<br>
                </li>
                <li>
                    <input id="email" name="email" :placeholder="$t('rememberpassword.seuEmail')" ref="email" type="text">
                </li>
                <li>
                    <input class="btn-red" ref="btn-submit" type="submit" :value="btn_submit_value">
                    <router-link class="btn-simple" to="/login">{{ $t('rememberpassword.voltar') }}</router-link>
                </li>
            </ul>
        </form>
    </div> -->
</template>

<script>
    import axios from 'axios';
    import {VueRecaptcha} from 'vue-recaptcha'
    import { mapActions, mapGetters } from "vuex";
    export default {
        name: 'RememberPassword',
        computed: {
           ...mapGetters(
               ['switch']
            ),
            show_captcha(){
                return (this.switch && this.switch.captcha && this.switch.captcha == 1)
            }
        },
        data() {
            return {
                btn_submit      : false,
                btn_submit_value: this.$i18n.t('rememberpassword.reiniciarSenha'),
                error           : null,
                message         : null,
                captcha:null,
                checkCaptcha: false
            }
        },
        components: {
            VueRecaptcha
        },
        methods: {
            ...mapActions([
                
            ]),
            block(v) {
                    this.btn_submit_value = (v ? (this.$i18n.t('rememberpassword.aguarde')) : this.$i18n.t('rememberpassword.entrar'));
                    this.$refs["btn-submit"].style.pointerEvents = (v ? 'none' : 'auto');
                    this.$refs["btn-submit"].style.opacity = (v ? '.4' : '1');
            },
            send(e) {
                this.error = null;
                
                const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9.])+\.([A-Za-z]{2,})$/;

                if (!emailRegex.test(this.$refs["email"].value)) this.error = this.$i18n.t('rememberpassword.enderecoInvalido');

                if (this.error) {
                    e.preventDefault();
                    this.$app.modal('error', this.$i18n.t('rememberpassword.erro'), this.error, null, null, null, "OK");
                } else {
                    this.sendRemember(e);
                }
            },
            async sendRemember(e) {
                if (!this.submit) {
                    this.block(true);
                    this.$app.analytics('forgot_password');
                    
                    let form_data = new FormData(this.$refs["remember-password-form"]);

                    try {
                        const res = await axios.post(this.$url_api + 'api/resetpwd', form_data,{headers:{'language': this.$i18n.locale,}});
                        this.rememberResponse(res);
                    } catch (error) {
                        this.rememberError(error.response);
                    }
                }

                e.preventDefault();
            },
            rememberResponse(obj) {
                if (obj.data.status == 'success') {
                    this.message = obj.data.message;
                    this.$app.modal('success', this.$i18n.t('rememberpassword.sucesso'), this.message, null, null, null, "OK");
                    this.$app.modal_RememberPassword = false;
                    this.$app.$refs['LoginModal'].openModal(true);
                }

                this.block(false);
            },
            rememberError(obj) {
                this.block(false);
                let str = '';
                for (let o in obj.data.errors) {
                    str += obj.data.errors[o] + '\n';
                }
                this.error = str;
                this.$app.modal('error', this.$i18n.t('rememberpassword.erro'), this.error, null, null, null, "OK");
                if (this.$refs.recaptcha) {
                    this.$refs.recaptcha.reset();
                    this.checkCaptcha = false
                }
            },
        },
        mounted() {
            if (this.$app.modal_RememberPassword && !this.show_captcha)
                this.checkCaptcha = true;
            this.$refs['email'].focus();
        },
        updated() {
            if (this.$app.modal_RememberPassword && !this.show_captcha)
                this.checkCaptcha = true;
            this.btn_submit_value = this.$i18n.t('rememberpassword.reiniciarSenha');
        },
        destroyed() {
        }
    }
</script>

<style lang="scss" scoped>
    .btn-simple {
        width: 100%;
        text-align: center;
        background-color: var(--buttons_color_2nd);
        color: var(--fonts_color_2nd);
        padding: 18px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        border: 0px solid;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .btn-simple:hover {
        transition: all 0.3s ease-in-out;
        background-color: var(--fonts_color_2nd);
        color: var(--buttons_color_2nd);
    }
    #esqueciSenha {
        @media(max-width: 575px) {
            margin-top: 100px;
            height: calc(100vh - 100px);
        }
    }
    #remember-password{
        z-index: 998;
    }
    #remember-password > form > ul > li
    {
        box-sizing: border-box;
        list-style: none;
        padding: 10px;
    }
    h3
    {
        color: #b62f32;
    }
    label
    {
        text-align: center;
    }
    .li-inline *
    {
        display: inline-block;
        vertical-align: middle;
    }
    .btn-blue, .btn-red
    {
        display: block;
        letter-spacing: 1px;
        padding: 15px 10px;
        width: 100%;
    }

    #email {
      margin: 10px !important;
    }

    .esqueciSenha__button {
      .button__buttonForm {
        transition: all 0.25s ease;
        &:hover {
          border: none;
        }
      }
    }

    @media screen and (max-width: 500px)
    {
        form
        {
            width: calc(100% - 40px);
        }
    }
</style>