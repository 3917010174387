import { render, staticRenderFns } from "./Political.vue?vue&type=template&id=58f0778c&scoped=true&"
import script from "./Political.vue?vue&type=script&lang=js&"
export * from "./Political.vue?vue&type=script&lang=js&"
import style0 from "./Political.vue?vue&type=style&index=0&id=58f0778c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f0778c",
  null
  
)

export default component.exports