import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import signup from './module/signup'
import LMS from './module/lms/LMS';
import LmsCourse from './module/lms/course/index';
import LmsCart from './module/lms/cart/index';
import FileManager from './module/lms/file_manager';
import UserProfile from './module/lms/user_profile';
import Friends from './module/lms/friends';
import Feed from './module/lms/feed';
import Journey from './module/lms/journey';
import TemplateHTML from "./module/template_html";
import Whiteboard from "./module/lms/whiteboard/index";
import Transcription from "./module/transcription";

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        age_verified     : false,
        age              : 0,
        logged           : false,
        isGuest          : false,
        started          : false,
        token            : null,
        email            : null,
        cpf              : null,
        birth            : null,
        company          : null,
        phone            : null,
        estado           : null,
        city             : null,
        url_actual       : null,
        email_verified   : false,
        type_validation  : false,
        reward_slug      : '',
        user_type        : null,
        is_streamer      : 0,
        group_id         : null,
        event_id         : null,
        session_id       : null,
        //user
        id               : 0,
        checkers         : 0,
        Ads              : [],
        is_guest         : false,
        AvatarInventory  : {},
        AvatarLook       : [],
        user_picture     : '',
        BlockedFriends   : [],
        EasterEggs       : [],
        NetworkType      : "",
        NetworkUrl       : "",
        RoomGuests       : "",
        Scenename        : "",
        TotalCoins       : 0,
        TotalPoints      : 0,
        TotalBadges      : 0,
        UserFriends      : [],
        UserRanking      : 0,
        UserRankingDaily : 0,
        UserBadges       : "",
        UserTickets      : "",
        EventimTickets   : "",
        NotificationsList: [],
        notifications_count: 0,
        notifications_pendent_ids: [],
        name             : "",
        nickname         : "",
        ref              : "",
        birth_day        : false,
        ticket_actual    : null,
        sessions         : {},
        groups           : {},
        switch           : {
            chat                : false,
            chat_audio          : 0,
            chat_max_unity      : 0,
            chat_max_krpano     : false,
            multiplayer         : false,
            avatar_shop         : false,
            nba_store           : false,
            websockets          : false,
            buy_with_eventim    : true,
            captcha             : false,
            log                 : true,
            captcha_client      : "",
            quiz                : true,
            Login_Facebook      : false,
            Login_Gmail         : false,
            Login_Azure         : false,
            has_packages        : false,
            guest_mode          : false,
            without_landing_page: '',
            Habilitar_Amigos    : false,
            Habilitar_convidar_amigos : false,
            Habilitar_Pontos    : false,
            Habilitar_Moedas    : false,
            Habilitar_Selfies   : false,
            Habilitar_Notificações_Header: false,
            radio               : false,
            Habilitar_Age_Gate  : false,
            url_chat            : "",
            optins              : [],
            Nome_Aba            : "",
            multilanguages      : false,
            btn_promotional     : {
                active: false,
                open_in: "",
                image: "",
                url_desktop:"",
                url_mobile: "",
                url_ios: ""
            },
            signup_extra_fields: [],
            regions: [],
            finance_profile_menu: false,
            ticket_gate_menu: false,
            user_ticket_gate_menu: false,
            event_menu: [],
            popup_points: [],
            azure_conf:{
                i:"",
                c:""
            },
            countdown_event: {
                enable_countdown: 0,
                countdown_title: "",
                countdown_date: "",
                countdown_image: ""
            },
            cookie_policies: null,
            libras_enable : 0
        },
        design          : {
            color:{
                buttons_color   : "",
                buttons_color_2nd : "",
                fonts_color     : "",
                fonts_color_2nd : "",

                headers_color        : "#FFFFFF",
                headers_color_2nd    : "#FFFFFF",
                headers_color_logged : "#FFFFFF",
                chat_bg_color        : "#FFFFFF",
                chat_bgheader_color  : "#FFFFFF",
                highlight_color      : "#FFFFFF"
            },
            font:{
                font_link       : "",
                font_size       : "",
            },
            footer_on       : true,
            logo            : "",
            logoFavicon     : "",
            copyright_footer: "",
            logo_footer:[],
            wizard:{
                title:"",
                url:"",
                description:"",
                play:false
            }
        },
        header_buttons: {
            notification_button: "",
            logoff_button: "",
            coins_button: "",
            points_button: "",
            badges_button: "",
            camera_button: "",
            library_button: "",
            radio_on_button: "",
            radio_off_button: "",
            language_button: "",
            help_button: "",
            menu_button: "",
            profile_button:"",
            friends_button:"",
            cart_button:"",
        },
        avatar          : "",
        use_avatar      : true,
        avatar_metaverse:  "",
        dat             : null,
        login_social    : false,
        change_password_social    : false,
        id_form_actual  : null,
        webview         : false,
        maps :{
            default:"",
            android:"",
            ios:"",
            safari:"",
        },
        current_session: {},
        smartphone_options: [],
        stores: null,
        store_oracle: null,
        store_vtex: null,
        store_magento: null,
        store_vt: null,
        store_urls: null,
        //----- oracle -----//
        check_log: false,
        counter: 0,
        //email:null,
        first_name:null,
        //id: null,
        last_name:null,
        level: null,
        level_next: null,
        points: 0,
        products_ids: [],
        products_n: [],
        products: [],
        score_needed_to_reach: null,
        thumb: null,
        cart_token: "",
        smartphone: "",
        LibraryName: "",
        use_map: true,
        // event_id: 0,
        map_sessions: null,
        internet_minimum_speed: 0,
        internet_test_url: null,
        internet_time_cache: 10,

        package:null,
        event_map: null,
        event_first_session: null,
        last_local: null,
        quest: null,
        unity_hash_log: {
            unity_hash_id: null,
            log_id: null,
            time: null
        },
        radio: [],
        url_after_registration: null,
        userOrigin: null,
        userAgent: navigator.userAgent || navigator.vendor || window.opera,
        securityCookie: false,
        securityContainer: null,
        is_mini_game: false
    },
    mutations: {
        decrement: state => state.coin--,
        increment: state => state.coin++,
        update_logged (state, data) {
            state.logged = data
        },
        update_guest (state, data) {
            state.isGuest = data
        },
        update_user_picture (state, data) {
            state.user_picture = data
        },
        update_id (state, data) {
            state.id = data
        },
        update_token (state, data) {
            state.token = data;
        },
        update_email (state, data) {
            state.email = data;
        },
        update_cpf (state, data) {
            state.cpf = data;
        },
        update_birth (state, data) {
            state.birth = data;
        },
        update_company (state, data) {
            state.company = data;
        },
        update_reward_slug (state, data) {
            state.reward_slug = data
        },
        update_phone (state, data) {
            state.phone = data;
        },
        update_state (state, data) {
            state.estado = data;
        },
        update_nationality (state, data) {
            state.nationality = data;
        },
        update_city (state, data) {
            state.city = data;
        },
        update_coin (state, data) {
            state.TotalCoins = data;
        },
        update_point (state, data) {
            state.TotalPoints = data;
        },
        update_totalbadges (state, data) {
            state.TotalBadges = data;
        },
        update_switch (state, data) {
            state.switch = data;
        },
        update_use_avatar(state, data) {
            state.use_avatar = data
        },
        update_avatar_metaverse(state, data) {
            state.avatar_metaverse = data
        },
        update_checkers (state, data) {
            state.checkers = data;
        },
        update_design (state, data) {
            state.design = {...state.design, ...data};
        },
        update_header_buttons (state, data) {
            state.header_buttons = data
        },
        update_avatar (state, data) {
            state.avatar = data;
        },
        update_event_id (state, data) {
            state.event_id = data;
        },
        update_profile (state, data) {
            state.Ads                    = data.Ads;
            state.AvatarInventory        = data.AvatarInventory;
            state.AvatarLook             = data.AvatarLook;
            state.BlockedFriends         = data.BlockedFriends;
            state.is_guest               = data.is_guest;
            state.NetworkType            = data.NetworkType;
            state.NetworkUrl             = data.NetworkUrl;
            state.RoomGuests             = data.RoomGuests;
            state.Scenename              = data.Scenename;
            state.TotalCoins             = data.TotalCoins
            state.TotalPoints            = data.TotalPoints
            state.TotalBadges            = data.TotalBadges
            state.UserFriends            = data.UserFriends;
            state.UserRanking            = data.UserRanking;
            state.UserRankingDaily       = data.UserRankingDaily;
            state.UserTickets            = data.UserTickets;
            state.UserBadges             = data.UserBadges;
            state.EventimTickets         = data.EventimTickets;
            state.is_streamer            = data.is_streamer,
            state.NotificationsList      = data.NotificationsList;
            state.name                   = data.name;
            state.nickname               = data.nickname;
            state.user_type              = data.user_type;
            state.group_id               = data.group_id;
            state.ref                    = data.ref;
            state.birth_day              = data.birth_day;
            state.sessions               = data.Sessions;
            state.switch                 = data.switch;
            state.design                 = data.design;
            state.avatar                 = data.avatar;
            state.age                    = data.age;
            state.login_social           = data.login_social;
            state.change_password_social = data.change_password_social;
            state.maps.default           = data.map?.default??"";
            state.maps.android           = data.map?.android??"";
            state.maps.ios               = data.map?.ios??"";
            state.maps.safari            = data.map?.safari??"";
            state.smartphone_options     = data.smartphone_options;
            state.smartphone             = data.smartphone;
            state.LibraryName            = data.LibraryName;
            state.event_id               = data.event_id;
            /* LMS */
            state.lms                    = data.lms;
            // state.session_id             = data.session_id;
            state.use_map                = data.use_map;
            // state.event_id                = data.event_id;
            state.map_sessions           = data.map_sessions;
            state.internet_minimum_speed = data.internet_minimum_speed;
            state.internet_test_url     = data.internet_test_url;
            state.internet_time_cache   = data.internet_time_cache;
            state.avatar_map             = data.event_avatar_map;
            state.avatar_first_session   = data.event_avatar_first_session;
            state.last_local             = data.last_local;
        },
        update_user_friends(state, data) {
            state.UserFriends  = data;
        },
        update_started(state, data) {
            state.started = data;
        },
        update_url_actual(state, data) {
            state.url_actual = data;
        },
        update_age_verified(state, data) {
            state.age_verified = data;
        },
        update_age(state, data) {
            state.age = data;
        },
        update_email_verified(state, data) {
            state.email_verified = data;
        },
        update_type_validation(state, data) {
            state.type_validation = data;
        },
        update_user_tickets(state, data) {
            state.UserTickets = data;
        },
        update_user_tickets_del(state, data) {
            state.UserTickets.splice(Number(data), 1);
        },
        update_ticket_actual(state, data) {
            state.ticket_actual = data;
        },
        update_nickname(state, data) {
            state.nickname = data;
        },
        update_name(state, data) {
            state.name = data;
        },
        update_dat(state, data) {
            state.dat = data;
        },
        update_notifications(state, data) {
            if (state.NotificationsList)
                state.NotificationsList.unshift(data);
            else
                state.NotificationsList = data;
        },
        update_notifications_count(state, count) {
            state.notifications_count = count > 99 ? "99+": count;
        },
        update_notifications_pendent_ids(state, data){
            state.notifications_pendent_ids = data;
        },
        update_websocket_notifications_pendent_ids(state, data){
            state.notifications_pendent_ids.push(data);
        },
        remove_websocket_notifications_pendent_ids(state, index){
            state.notifications_pendent_ids.splice(index, 1);
        },
        update_add_notifications_count(state) {
            state.notifications_count = state.notifications_count + 1;
        },
        update_notifications_del(state, data) {
            state.NotificationsList.splice(Number(data), 1);
        },
        update_notifications_private_message(state, data) {
            if( state.NotificationsList.some( obj => obj.friend_id == data.friend_id) ) {
                return;
            }
            state.NotificationsList.unshift(data);
        },
        update_notifications_notify_points(state, data) {
            if( state.NotificationsList.some( obj => obj.id == data.notification_id) ) {
                return;
            }
            state.NotificationsList.unshift(data);
        },
        update_notification_with_id(state, data) {
             const newArray = state.NotificationsList.map(item=> {
                if(item.notification_id === data.notification_id)
                    return {...data};
                return item
            });
             state.NotificationsList = [...newArray];
        },
        update_notification_datas(state, data){
            const newArray = state.NotificationsList.map(item=> {
                let filter = data.filter(I=> I.notification_id === item.notification_id)
                if(filter.length>0)
                    return {...filter[0]};
                return item
            });
            state.NotificationsList = [...newArray];
        },
        update_login_social(state, data) {
            state.login_social = data;
        },
        update_change_password_social(state, data) {
            state.change_password_social = data;
        },
        update_eventim_tickets(state, data) {
            state.EventimTickets = data;
        },
        update_id_form_actual(state, data) {
            state.id_form_actual = data;
        },
        update_sessions(state, data) {
            state.sessions = data;
        },
        update_webview(state, data) {
            state.webview = data;
        },
        update_current_session(state, data) {
            state.current_session = data;
        },
        update_smartphone_options(state, data) {
            state.smartphone_options = data;
        },
        update_groups(state, data) {
            state.groups = data;
        },
        update_stores(state, data) {
            state.stores = data;
            for(let o in data)
            {
                if (data[o].type == 1)
                    state.store_oracle = 1;
                if (data[o].type == 2)
                {
                    state.store_vtex = 1;
                    state.store_urls = { 'url_api' : data[o].url_api };
                }
                if (data[o].type == 3)
                    state.store_magento = 1;
                if (data[o].type == 4)
                {
                    state.store_vt = 1;
                    state.store_urls = { 'url_api' : data[o].url_api };
                }
            }
        },

        //----- oracle -----//
        //decrement: state => state.counter--,
        //increment: state => state.counter++,
        update (state, data) {
            state.token = data;
            state.logged = (data !== null) ? true : false;
        },
        update_cart (state, data) {
            state.counter = data;
        },
        update_check_log (state, data) {
            state.check_log = data;
        },
        update_cart_products_array (state, data) {
            state.products_ids = [];
            state.products_n = [];
            state.products = [];
            if(data) {
                data.forEach(function(item) {
                    let index = state.products_ids.indexOf(item.cart_id);
                    if (index !== -1) {
                        state.products[index] = item;
                        state.products_n[index] = item.quantity;
                    } else {
                        // state.products_ids.push(item.id);
                        state.products_ids.push(item.cart_id);
                        state.products_n.push(item.quantity);
                        state.products.push(item);
                    }
                    localStorage.setItem('cart_store', JSON.stringify(state.products));
                });
            }
            else {
                localStorage.removeItem('cart_store');
            }
            let total_items = 0;
            for (let o in state.products) {
                total_items += state.products[o].quantity;
            }
            //state.counter = state.products.length;
            state.counter = total_items;
        },
        update_cart_products (state, data) {
            if (data) {
                let index = state.products_ids.indexOf(data.cart_id);

                console.log('### here meu amigo => ', index, state.products_ids, data.id);
                if (index !== -1) {
                    state.products[index] = data;
                    state.products_n[index] = data.quantity;
                } else {
                    state.products_ids.push(data.cart_id);
                    state.products_n.push(data.quantity);
                    state.products.push(data);
                }
                localStorage.setItem('cart_store', JSON.stringify(state.products));
            }
            else {
                state.products_ids = [];
                state.products_n = [];
                state.products = [];
                localStorage.removeItem('cart_store');
            }
            let total_items = 0;
            for (let o in state.products) {
                total_items += state.products[o].quantity;
            }
            //state.counter = state.products.length;
            state.counter = total_items;
        },
        del_product_cart (state, data) {
            if (data) {
                // let index = state.products_ids.indexOf(Number(data.id));
                let index = state.products_ids.indexOf(Number(data.cart_id));
                console.log('index => ', state.products_ids, index, data.cart_id);
                if (index !== -1) {
                    state.products_ids.splice(index, 1);
                    state.products_n.splice(index, 1);
                    state.products.splice(index, 1);
                    state.counter = state.products.length;

                    localStorage.setItem('cart_store', JSON.stringify(state.products));
                }
            }
        },
        /*
        update_profile (state, data) {
            state.email      = data.email;
            state.id         = data.id;
            state.first_name = data.first_name;
            state.last_name  = data.last_name;
        },
        */
        update_points (state, data) {
            if (data) {
                //state.points                = data.total_points;
                state.points                = data.redeemable_points;
                try { state.level                 = data.tier_info.current_level.title; } catch(e) { e; }
                try { state.level_next            = data.tier_info.next_level.title; } catch(e) { e; }
                try { state.score_needed_to_reach = data.tier_info.score_needed_to_reach; } catch(e) { e; }
                //state.thumb                 = data.tier_info.current_level.image_url;
            } else {
                state.points                = 0;
                state.level                 = null;
                state.level_next            = null;
                state.score_needed_to_reach = null;
            }
        },
        update_cart_token(state, data) {
            if (!data){
                data = "";
            }
            localStorage.setItem('cart_token', data);
            state.cart_token = data;
        },
        //----- \oracle\ -----//
        update_package(state, data) {
            state.package = data;
        },
        //----- \quest\ -----//
        update_quest (state, data) {
            state.quest = data
        },
        update_unity_hash_log (state, data) {
            state.unity_hash_log.log_id = data.log_id
            state.unity_hash_log.unity_hash_id = data.unity_hash_id
        },
        update_time_unity_hash_log (state, data) {
            if (data == null && state.unity_hash_log.time!= null){
                clearTimeout(state.unity_hash_log.time)
            }
            state.unity_hash_log.time = data
        },
        update_radio(state, data) {
            state.radio = data;
        },
        update_url_after_registration(state, url) {
            state.url_after_registration = url;
        },
        update_user_origin(state, from) {
            state.userOrigin = from;
        },
        update_user_agent(state, from) {
            state.userAgent = from;
        },
        update_security_cookie(state, data) {
            state.securityCookie = data;
        },
        update_security_container(state, data) {
            state.securityContainer = data;
        },
        update_mini_game(state, data) {
            state.is_mini_game = data;
        }
    },
    getters: {
        logged:            state => state.logged,
        isGuest:           state => state.isGuest,
        started:           state => state.started,
        token:             state => state.token,
        email:             state => state.email,
        url_actual:        state => state.url_actual,
        age_verified:      state => state.age_verified,
        age:               state => state.age,
        email_verified:    state => state.email_verified,
        type_validation:    state => state.type_validation,
        user_type:         state => state.user_type,
        group_id:          state => state.group_id,
        event_id:          state => state.event_id,
        session_id:        state => state.session_id,
        id:                state => state.id,
        Ads:               state => state.Ads,
        reward_slug:       state => state.reward_slug,
        user_picture:      state => state.user_picture,
        use_avatar:        state => state.use_avatar,
        avatar_metaverse:  state => state.avatar_metaverse,
        is_streamer:       state => state.is_streamer,
        AvatarInventory:   state => state.AvatarInventory,
        AvatarLook:        state => state.AvatarLook,
        BlockedFriends:    state => state.BlockedFriends,
        EasterEggs:        state => state.EasterEggs,
        NetworkType:       state => state.NetworkType,
        NetworkUrl:        state => state.NetworkUrl,
        RoomGuests:        state => state.RoomGuests,
        is_guest:          state => state.is_guest,
        Scenename:         state => state.Scenename,
        TotalCoins:        state => state.TotalCoins,
        TotalPoints:       state => state.TotalPoints,
        TotalBadges:       state => state.TotalBadges,
        UserFriends:       state => state.UserFriends,
        UserRanking:       state => state.UserRanking,
        UserRankingDaily:  state => state.UserRankingDaily,
        UserTickets:       state => state.UserTickets,
        UserBadges:        state => state.UserBadges,
        EventimTickets:    state => state.EventimTickets,
        NotificationsList: state => state.NotificationsList,
        notifications_count: state => state.notifications_count,
        notifications_pendent_ids: state => state.notifications_pendent_ids,
        name:              state => state.name,
        nickname:          state => state.nickname,
        birth:             state => state.birth,
        cpf:               state => state.cpf,
        company:           state => state.company,
        nationality:       state => state.nationality,
        estado:            state => state.estado,
        city:              state => state.city,
        checkers:          state => state.checkers,
        ref:               state => state.ref,
        birth_day:         state => state.birth_day,
        ticket_actual:     state => state.ticket_actual,
        sessions:          state => state.sessions,
        switch:            state => state.switch,
        design:            state => state.design,
        header_buttons:    state => state.header_buttons,
        avatar:            state => state.avatar,
        dat:               state => state.dat,
        login_social:      state => state.login_social,
        change_password_social:      state => state.change_password_social,
        id_form_actual:    state => state.id_form_actual,
        webview:           state => state.webview,
        maps:               state => state.maps,
        current_session:   state=> state.current_session,
        smartphone_options:              state=> state.smartphone_options,
        groups:            state=> state.groups,
        stores:            state=> state.stores,
        store_oracle:      state=> state.store_oracle,
        store_vtex:        state=> state.store_vtex,
        store_magento:     state=> state.store_magento,
        store_vt:          state=> state.store_vt,
        store_urls:        state=> state.store_urls,
        
        //---- oracle -----//
        counter:               state => state.counter,
        //email:                 state => state.email,
        first_name:            state => state.first_name,
        //id:                    state => state.id,
        last_name:             state => state.last_name,
        level:                 state => state.level,
        level_next:            state => state.level_next,
        
        //logged:                state => state.logged,
        points:                state => state.points,
        products:              state => state.products,
        products_ids:          state => state.products_ids,
        products_n:            state => state.products_n,
        score_needed_to_reach: state => state.score_needed_to_reach,
        thumb:                 state => state.thumb,
        //token:                 state => state.token,
        check_log:             state => state.check_log,
        cart_token:             state => state.cart_token,
        smartphone:             state => state.smartphone,
        LibraryName:             state => state.LibraryName,
        use_map:             state => state.use_map,
        // event_id:             state => state.event_id,
        map_sessions:             state => state.map_sessions,
        internet_minimum_speed:             state => state.internet_minimum_speed,
        internet_test_url:             state => state.internet_test_url,
        internet_time_cache:             state => state.internet_time_cache,
        //---- \oracle\ -----//
        checkIOS(state) {
            return /iPad|iPhone|iPod/i.test(state.userAgent);
        },
        checkMobile(state) {
            // let memory = navigator.deviceMemory||0;
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(state.userAgent) /*&& memory < 8*/;
        },
        checkSafari(state) {
            // let memory = navigator.deviceMemory||0;
            return /^((?!chrome|android).)*safari/i.test(state.userAgent) /*&& memory < 8*/;
        },
        isMobile(state) {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(state.userAgent);//duplicate of checkMobile
        },
        package:              state => state.package,
        avatar_map:           state => state.avatar_map,
        avatar_first_session: state => state.avatar_first_session,
        last_local:           state => state.last_local,
        //---- quests -----//
        quest:                state => state.quest,
        unity_hash_log:       state => state.unity_hash_log,
        radio:                state => state.radio,
        url_after_registration: state => state.url_after_registration,
        userOrigin:           state => state.userOrigin,

        securityCookie:       state => state.securityCookie,
        securityContainer:    state => state.securityContainer,
        libras_enable: state => state.switch.libras_enable,
        is_mini_game: state => state.is_mini_game

    },
    actions: {
        decrement: ({ commit }) => commit('decrement'),
        increment: ({ commit }) => commit('increment'),
        update_logged: (state, data) => {
            state.commit('update_logged', data);
        },
        update_guest: (state, data) => {
            state.commit('update_guest', data)
        },
        update_token: (state, data) => {
            state.commit('update_token', data);
        },
        update_id: (state, data) => {
            state.commit('update_id', data);
        },
        update_email: (state, data) => {
            state.commit('update_email', data);
        },
        update_cpf: (state, data) => {
            state.commit('update_cpf', data);
        },
        update_company: (state, data) => {
            state.commit('update_company', data);
        },
        update_birth: (state, data) => {
            state.commit('update_birth', data);
        },
        update_state: (state, data) => {
            state.commit('update_state', data);
        },
        update_nationality: (state, data) => {
            state.commit('update_nationality', data);
        },
        update_city: (state, data) => {
            state.commit('update_city', data);
        },
        update_coin: (state, data) => {
            state.commit('update_coin', data);
        },
        update_point: (state, data) => {
            state.commit('update_point', data);
        },
        update_reward_slug: (state, data) => {
            state.commit('update_reward_slug', data);
        },
        update_event_id: (state, data) => {
            state.commit('update_event_id', data);
        },
        update_profile: (state, data) => {
            state.commit('update_profile', data);
        },
        update_user_friends: (state, data) => {
            state.commit('update_user_friends', data);
        },
        update_user_picture: (state, data) => {
            state.commit('update_user_picture', data)
        },
        update_started: (state, data) => {
            state.commit('update_started', data);
        },
        update_url_actual: (state, data) => {
            state.commit('update_url_actual', data);
        },
        update_age_verified: (state, data) => {
            state.commit('update_age_verified', data);
        },
        update_age: (state, data) => {
            state.commit('update_age', data);
        },
        update_use_avatar: (state, data) => {
            state.commit('update_use_avatar', data)
        },
        update_avatar_metaverse: (state, data) => {
            state.commit('update_avatar_metaverse', data)
        },
        update_email_verified: (state, data) => {
            state.commit('update_email_verified', data);
        },
        update_type_validation: (state, data) => {
            state.commit('update_type_validation', data);
        },
        update_checkers: (state, data) => {
            state.commit('update_checkers', data)
        },
        update_user_tickets: (state, data) => {
            state.commit('update_user_tickets', data);
        },
        update_user_tickets_del: (state, data) => {
            state.commit('update_user_tickets_del', data);
        },
        update_ticket_actual: (state, data) => {
            state.commit('update_ticket_actual', data);
        },
        update_nickname: (state, data) => {
            state.commit('update_nickname', data);
        },
        update_name: (state, data) => {
            state.commit('update_name', data);
        },
        update_dat: (state, data) => {
            state.commit('update_dat', data);
        },
        update_notifications: (state, data) => {
            state.commit('update_notifications', data);
        },
        update_notifications_count: (state, data) => {
            state.commit('update_notifications_count', data);
        },
        update_notifications_pendent_ids: (state, data) => {
            state.commit('update_notifications_pendent_ids', data);
        },
        update_websocket_notifications_pendent_ids(state, notificationId) {

            if(!state.state.notifications_pendent_ids.includes(notificationId)) {
                state.commit('update_websocket_notifications_pendent_ids', notificationId);
            }
        },
        remove_websocket_notifications_pendent_ids(state, data) {
            let index = state.state.notifications_pendent_ids.indexOf(data.notificationId);

            if(index !== -1) {
                state.commit('remove_websocket_notifications_pendent_ids', index);
            }

        },
        update_add_notifications_count: (state) => {
            state.commit('update_add_notifications_count');
        },
        update_notifications_private_message: (state, data) => {
            state.commit('update_notifications_private_message', data);
        },
        update_notifications_notify_points: (state, data) => {
            state.commit('update_notifications_notify_points', data);
        },
        update_switch: (state, data) => {
            state.commit('update_switch', data);
        },
        update_design: (state, data) => {
            state.commit('update_design', data);
        },
        update_header_buttons: (state, data) => {
            state.commit('update_header_buttons', data)
        },
        update_notifications_del: (state, data) => {
            state.commit('update_notifications_del', data);
        },
        update_notification_with_id: (state, data) => {
            state.commit('update_notification_with_id', data);
        },
        update_notification_datas: (state, data) => {
            state.commit('update_notification_datas', data);
        },
        update_login_social: (state, data) => {
            state.commit('update_login_social', data);
        },
        update_change_password_social: (state, data) => {
            state.commit('update_change_password_social', data);
        },
        update_eventim_tickets: (state, data) => {
            state.commit('update_eventim_tickets', data);
        },
        update_id_form_actual: (state, data) => {
            state.commit('update_id_form_actual', data);
        },
        update_sessions: (state, data) => {
            state.commit('update_sessions', data);
        },
        update_webview: (state, data) => {
            state.commit('update_webview', data);
        },
        update_current_session: (state,data)=> {
            state.commit('update_current_session', data);
        },
        update_groups: (state,data)=> {
            state.commit('update_groups', data);
        },
        update_smartphone_options: (state,data)=>{
            state.commit('update_smartphone_options',data);
        },
        update_stores: (state,data)=>{
            state.commit('update_stores',data);
        },

        //----- oracle -----//
        //decrement: ({ commit }) => commit('decrement'),
        //increment: ({ commit }) => commit('increment'),
        update: (state, data) => {
            state.commit('update', data);
        },
        update_cart: (state, data) => {
            state.commit('update_cart', data);
        },
        update_cart_products: (state, data) => {
            state.commit('update_cart_products', data);
        },
        update_cart_products_array: (state, data) => {
            state.commit('update_cart_products_array', data);
        },
        del_product_cart: (state, data) => {
            state.commit('del_product_cart', data);
        },
        /*
        update_profile: (state, data) => {
            state.commit('update_profile', data);
        },*/
        update_points: (state, data) => {
            state.commit('update_points', data);
        },
        update_check_log: (state, data) => {
            state.commit('update_check_log', data);
        },
        //----- \oracle\ -----//
        update_cart_token: (state,data)=>{
            state.commit('update_cart_token', data);
        },
        update_package: (state,data)=>{
            state.commit('update_package', data);
        },
        //----- \quest\ -----//
        update_quest: (state,data)=>{
            state.commit('update_quest', data);
        },
        update_unity_hash_log: (state,data)=>{
            state.commit('update_unity_hash_log', data);
        },
        reset_unity_hash_log: (state)=>{
            state.commit('update_time_unity_hash_log', null);
            state.commit('update_unity_hash_log', {log_id: null, unity_hash_id: null});
        },
        update_time_unity_hash_log: (state,data)=>{
            state.commit('update_time_unity_hash_log', data);
        },
        update_radio: (state, radio) => {
            state.commit('update_radio', radio);
        },
        update_url_after_registration: (state, url) => {
            state.commit('update_url_after_registration', url);
        },
        update_user_origin: (state, from) => {
            state.commit('update_user_origin', from);
        },
        update_user_agent: (state, from) => {
            state.commit('update_user_agent', from);
        },
        update_security_cookie: (state, data) => {
            state.commit('update_security_cookie', data);
        },
        update_security_container: (state, data) => {
            state.commit('update_security_container', data);
        },
        update_totalbadges: (state, from) => {
            state.commit('update_totalbadges', from);
        },
        update_mini_game: (state, data) => {
            state.commit('update_mini_game', data);
        }
    },
    modules: {
        signup: signup,
        LMS: LMS,
        LmsCourse: LmsCourse,
        LmsCart: LmsCart,
        FileManager: FileManager,
        UserProfile: UserProfile,
        Feed: Feed,
        Journey: Journey,
        Friends: Friends,
        TemplateHTML: TemplateHTML,
        Whiteboard: Whiteboard,
        Transcription: Transcription,
    },
    plugins: [
        createPersistedState({
            paths:["signup"],
            key:"per-stores"
        })
    ]
})

export { store }
