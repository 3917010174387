<template>
    <div class="smart-summary">
<!--        loader-->
        <div v-if="loader" class="row">
            <div class="col-12">
                <div class="ui active centered inline loader"></div>
            </div>
        </div>
        <div v-if="!loader" class="row">
            <div class="col-12">
                <strong>
                    Descreva o tópico que deseja resumir
                </strong>
            </div>
            <div class="col-12">
                <textarea v-model="message" @keyup.enter="sendMessage" placeholder="Digite sua mensagem"></textarea>
            </div>
            <div class="col-12 mb-3">
                <div class="ui list suggestions">
                    <div class="item suggestion" v-for="(suggestion,index) in suggestions" @click="message = suggestion" :key="index">
                        {{suggestion}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <button class="ui button primary w-100" @click="sendMessage">Enviar</button>
            </div>
        </div>
        <hr v-if="!loader">
        <div v-if="!loader && last_msg" class="last_message_container">
<!--            title-->
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center">Resumo Inteligente</h3>
                </div>
            </div>
            <div class="last_message">
                <div class="row">
                    <div class="col-12">
                        <div class="last_message_html" v-html="last_msg"></div>
                    </div>
                </div>
            </div>
            <div class="last_message_icons">
                <hr>
<!--                <button class="ui button inverted" @click="sendMessage">
                    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.78125 2.21875L11.3438 0.65625V5.34375H6.65625L8.8125 3.1875C8.02083 2.39583 7.08333 2 6 2C4.89583 2 3.94792 2.39583 3.15625 3.1875C2.38542 3.95833 2 4.89583 2 6C2 7.10417 2.38542 8.05208 3.15625 8.84375C3.94792 9.61458 4.89583 10 6 10C6.77083 10 7.52083 9.73958 8.25 9.21875C9 8.69792 9.51042 8.07292 9.78125 7.34375H11.1562C10.8646 8.51042 10.2396 9.46875 9.28125 10.2188C8.32292 10.9688 7.22917 11.3438 6 11.3438C4.54167 11.3438 3.29167 10.8229 2.25 9.78125C1.20833 8.73958 0.6875 7.47917 0.6875 6C0.6875 4.52083 1.20833 3.26042 2.25 2.21875C3.29167 1.17708 4.54167 0.65625 6 0.65625C6.625 0.65625 7.30208 0.8125 8.03125 1.125C8.78125 1.41667 9.36458 1.78125 9.78125 2.21875Z" fill="white"/>
                    </svg>
                </button>-->
                <button class="ui button inverted" @click="copyLast">
                    <svg width="16" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.6562 14V4.65625H4.34375V14H11.6562ZM11.6562 3.34375C12.0104 3.34375 12.3229 3.47917 12.5938 3.75C12.8646 4 13 4.30208 13 4.65625V14C13 14.3542 12.8646 14.6667 12.5938 14.9375C12.3229 15.2083 12.0104 15.3438 11.6562 15.3438H4.34375C3.98958 15.3438 3.67708 15.2083 3.40625 14.9375C3.13542 14.6667 3 14.3542 3 14V4.65625C3 4.30208 3.13542 4 3.40625 3.75C3.67708 3.47917 3.98958 3.34375 4.34375 3.34375H11.6562ZM9.65625 0.65625V2H1.65625V11.3438H0.34375V2C0.34375 1.64583 0.46875 1.33333 0.71875 1.0625C0.989583 0.791667 1.30208 0.65625 1.65625 0.65625H9.65625Z" fill="white"/>
                    </svg>
                </button>
                <button class="ui button primary" @click="downloadSummary">
                    <svg width="16" height="16" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.34375 11.3438V10H9.65625V11.3438H0.34375ZM9.65625 4L5 8.65625L0.34375 4H3V0H7V4H9.65625Z" fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
        <Chat ref="chat" :messages_chat="messages_chat" />
    </div>
</template>
<script>
import Chat from "@/components/avia/Chat.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {Chat},
    name: 'SmartSummary',
    data() {
        return {
            message: '',
            messages: [],
            avia: {
                max_tokens: 4096//max of chat4
            },
            suggestions: [
                "Resumo da conversa",
                "Primeiros 15 minutos",
                "Últimos 15 minutos",
                "Quem falou mais",
                "Quem falou menos",
                "Quanto tempo durou a conversa",
            ],
        }
    },
    computed: {
        ...mapGetters('Transcription', [
            'opened_transcription', 'messages_chat', 'loader'
        ]),
        personality(){
            let system_content = [
                "Você é uma IA que recebeu a transcrição de uma conversa e deve responder qualquer pergunta que o usuário fizer sobre ela ou sobre o que foi dito.",
                "Aqui estão as transcrições que você recebeu:"
            ];
            this.opened_transcription.forEach((transcription) => {
                system_content.push(`${transcription.nickname} at ${transcription.created_at} ${transcription.content}`);
            });
            return [
                {
                    "role": "system",
                    "content": system_content.join('|'),
                }
            ];
        },
        last_msg(){
            let messages = this.messages_chat;
            if (messages && messages.length > 0) {
                let content = messages.filter((msg) => msg.role === 'assistant').pop()?.content;
                if (content) {
                    content = content.replaceAll(/(\r\n|\n|\r)/gm, "\n").replaceAll(/\n/gm, "<br>")
                    content = content.replaceAll(/(\*\*|__)(.*?)(\*\*|__)/gm, "<strong>$2</strong>");
                    content = content.replaceAll(/(\*|_)(.*?)(\*|_)/gm, "<em>$2</em>");
                    content = content.replaceAll(/(`)(.*?)(`)/gm, "<code>$2</code>");
                    content = content.replaceAll(/(~~)(.*?)(~~)/gm, "<del>$2</del>");
                    content = content.replaceAll(/(\[)(.*?)(\]\()(.*?)(\))/gm, "<a href='$4'>$2</a>");
                    return content;
                }
            }
            return null;
        },
        last_msg_copy(){
            let messages = this.messages_chat;
            if (messages && messages.length > 0) {
                let content = messages.filter((msg) => msg.role === 'assistant').pop()?.content;
                if (content) {
                    return content;
                }
            }
            return null;
        },
        last_send(){
            let messages = this.messages_chat;
            if (messages && messages.length > 0) {
                let content = messages.filter((msg) => msg.role === 'user').pop()?.content;
                if (content) {
                    return content;
                }
            }
            return null;
        }
    },
    mounted() {
        this.messages = this.messages_chat;
    },
    methods: {
        ...mapActions('Transcription', [
            'setMessagesChat','setLoader'
        ]),
        sendMessage() {
            this.block(true);
            this.messages.push({role: 'user', content: this.message});
            this.$refs.chat.send(this.message);
            this.message = '';
        },
        addMessage(msg, user) {
            this.block(false);
            this.messages.push({role: user ? 'user' : 'assistant', content: msg});
            this.setMessagesChat(this.messages);
        },
        block(block) {
           this.setLoader(block);
        },
        copyLast(){
            let el = document.createElement('textarea');
            el.value = this.last_msg_copy;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        downloadSummary(){
            let el = document.createElement('a');
            el.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.last_send + "\n\n" + this.last_msg_copy);
            el.download = 'resumo-inteligente.txt';
            el.click();
        }
    }
}
</script>
<style scoped lang="scss">
* {
    font-family: Exo, sans-serif;
}
textarea,.textarea-loading {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    resize: none;
}
.textarea-loading{
    display: flex;
    align-items: center;
    justify-content: center;
}
.button .loader{
    height: 1.5rem;
    width: 1.5rem;
    &:before,&:after{
        height: 1.5rem;
        width: 1.5rem;
    }
}
.row {
    margin-top: 10px;
    margin-bottom: 10px;
}
.smart-summary{
    //d-flex flex-column h-100 w-100
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 0 0;
    overflow: hidden;
    overflow-y: auto;
    .row{
        margin: 0;
        width: 100%;
        .col-12{
            padding: 0;
        }
    }
    //if scroll max-width: 97%
    >*{
        max-width: 97%;
    }
}
.last_message_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    .last_message{
        overflow: hidden;
        font-size: 1rem;
        text-shadow: rgba(0,0,0,0.5) 0 4px 4px;
        width: 100%;

    }
    .last_message_icons{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        button{
            padding: 8px;
            &.inverted{
                background-color: transparent;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3) inset;
                color: white;
                &:hover{
                    background-color: rgba(255, 255, 255, 0.1);
                    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3) inset;
                }
            }
        }
        button:not(:last-child){
            margin-right: 10px;
        }
        hr{
            flex: 1 0 0;
            margin-right: 10px;
        }
    }
}
.suggestions {
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    .suggestion{
        font-size: 1rem;
        padding: 10px!important;
        margin-bottom: 8px;
        border-radius: 25px;
        cursor: pointer;
        white-space: nowrap;
        background-color: transparent;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3) inset;
        color: white;
        &:hover{
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3) inset;
        }
        &:not(:last-child){
            margin-right: 10px;
        }
    }
}
</style>