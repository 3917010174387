<template>
    <div ref="lang_dropdown" class="lang_dropdown ui inline suidropdown" :class="{with_text}">
        <div class="text">
            <div :data-value="$i18n.locale">
                <i v-if="$i18n.locale === `pt-br`" class="br flag" />
                <i v-else-if="$i18n.locale === `en`" class="us flag" />
                <i v-else :class="`${$i18n.locale} flag`" />
                <span v-if="with_text">{{$t(current_lang.language)}}</span>
            </div>
        </div>
        <i class="dropdown icon" />
        <div class="menu">
            <div v-for="lang in langs_active" :key="lang.code" class="item"
                 :data-value="lang.code">
                <i v-if="lang.code === `pt-br`" class="br flag" />
                <i v-else-if="lang.code === `en`" class="us flag" />
                <i v-else :class="`${lang.code} flag`" />
                <span v-if="with_text">{{$t(lang.language)}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
    name: 'LangDropDown',
    props:{
        with_text:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters([
            'switch'
        ]),
        langs_active(){
            this.langs.forEach(option => {
                option.active = false;
            });
            if(this.switch.multilanguages && this.switch.active_languages) {
                this.langs.forEach(lang => {
                    lang.active = Boolean(this.switch.active_languages[lang.code]);
                });
            }
            return this.langs.filter(option => option.active);
        },
        current_lang(){
            return this.langs.find(option => option.code === this.$i18n.locale);
        }
    },
    data() {
        return {
            langs:[
                {
                    language: 'Português',
                    code: "pt-br",
                    active: true
                },
                {
                    language: 'Inglês',
                    code: "en",
                    active: true
                },
                {
                    language: 'Espanhol',
                    code: 'es',
                    active: true
                },
                {
                    language: 'Francês',
                    code: 'fr',
                    active: true
                }
            ],
        }
    },
    methods: {
        init(){
            if (this.$refs.lang_dropdown.tabIndex === -1) {
                window.$(this.$refs.lang_dropdown).suidropdown({
                    onChange: (value) => {
                        this.$app.setLocale(value);
                    }
                });
            }
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.init()
        });
    },
    updated() {
        this.$nextTick(() => {
            this.init()
        });
    }
}
</script>
<style scoped lang="scss">
.lang_dropdown{
    color: var(--fonts_color);
    .icon{
        color: var(--fonts_color)!important;
    }
    &.with_text{
        border-radius: 0.625rem;
        border: 1px solid var(--fonts_color);
        >.menu{
            top:100%;
            left: 0!important;
            right: auto;
            width: 100%;
        }
    }
    >.menu{
        background-color: var(--headers_color_logged);
        &>.item{
            &:hover{
                background: rgba(var(--buttons_color-r),var(--buttons_color-g),var(--buttons_color-b),.1);
            }
        }
    }
    .text>div,.menu>.item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.5em;
        i{
            font-size: 24px;
            margin-right: 0!important;
        }
    }
}
</style>