import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import { store } from './store/vuex';

import VueGtag from "vue-gtag";
import Zendesk from '@dansmaculotte/vue-zendesk';
import Quasar from 'quasar';

import VueApexCharts from 'vue-apexcharts';
import Pagination from 'vue-pagination-2';
const moment = require('moment');

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import './ml';


Vue.use(Quasar,{
  config:{},
})
try {
  // Local
  const conf = require('./conf.js');
  Vue.prototype.$url = conf.url;
  Vue.prototype.$url_api = conf.url_api;
}catch (e){
  // Prod
  Vue.prototype.$url = `https://${location.host}/`;
  if (location.host.indexOf("vrglass")!==-1 || location.host.indexOf("virtual.town")!==-1) {
    Vue.prototype.$url_api = `https://api-${location.host.replace("front-", "")}/`;
  }else{
    Vue.prototype.$url_api = `https://api.${location.host}/`;
  }
}

Vue.config.productionTip = false

import VueMask from "v-mask";
Vue.use(VueMask);

import VueMeta from 'vue-meta'
import i18n from './i18n'
import axios from "axios";
Vue.use(VueMeta)
Vue.use(VueApexCharts);
Vue.use(VueSweetalert2);
Vue.component('pagination', Pagination);
Vue.component('apexchart', VueApexCharts);
Vue.use(require('vue-moment'), {
  moment
});

axios.get(Vue.prototype.$url_api+'api/setupInit').then(function (response){
  if(response.data.analytics) {
    Vue.use(VueGtag, {
      config: {id: response.data.analytics_code,}
    }, router);
  }
  if(response.data.zendesk) {
    Vue.use(Zendesk, {
      //key: '223e0a34-ce60-4af2-bfe8-554fbdfefc9c',
      key: response.data.zendesk,
      disabled: false,
      hideOnLoad: true,
      settings: {
        webWidget: {
          color: {
            theme: response.data.btn_color??'#283a6c'
          },
          position: { horizontal: 'right', vertical: 'bottom' }
        }
      }
    })
  }
  /*
  if(response.data.comscore) {
    let script = document.createElement('script');
    script.id = "cookie-comscore";
    script.innerHTML = `var _comscore = _comscore || [];
      _comscore.push({ c1: "2", c2: "${response.data.comscore_code}" });
      (function() {
      var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
      s.src = "https://sb.scorecardresearch.com/cs/${response.data.comscore_code}/beacon.js";
      el.parentNode.insertBefore(s, el);
    })();`;
    let noscript = document.createElement('noscript')
    let img = document.createElement('img')
    img.src = `https://sb.scorecardresearch.com/p?c1=2&amp;c2=${response.data.comscore_code}&amp;cv=3.6.0&amp;cj=1`
    noscript.append(img);
    document.body.append(script,noscript);
  }
  if(response.data.matomo) {
    let script = document.createElement('script');
    script.innerHTML = `
        var _mtm = window._mtm = window._mtm || [];
        var _paq = window._paq = window._paq || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        if(JSON.parse(localStorage.getItem('user'))?.email){
            _paq.push(["setUserId", JSON.parse(localStorage.getItem('user'))?.email]);
        }
        (function() {
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='https://analytics.virtual.town/js/container_${response.data.matomo_code}.js'; s.parentNode.insertBefore(g,s);
        })();`;
    script.id = "cookie-matomo";
    document.body.append(script);
  }
  if(response.data.google_tagmanager) {
    let script = document.createElement('script');
    script.id = "cookie-google-tag-manager";
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${response.data.google_tagmanager_code}');`;
    let noscript = document.createElement('noscript');
    let iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${response.data.google_tagmanager_code}`;
    iframe.height = 0;
    iframe.width = 0;
    iframe.style = "display:none;visibility:hidden";
    
    noscript.append(iframe);

    document.body.append(script,noscript);
  }
  */
}).catch(function () {
}).finally(function (){
    new Vue({
        i18n,
        router,
        store,
        render: h => h(App),
    }).$mount('#app')
})



