<template>
    <div id="modal" ref="modal" class="modals bgBlack" :class="{'isMobileIframe':isMobileIframe,'btnPromotional':sub_t!=='btnPromotional'}">
        <div class="bg_background" data-response="0" @click="confirmationResponse"/>
        <div :class="{'modalGerais':true,'tutorial':t==='iframe'||t==='image','lightbox-pdf': modalFs&&t==='iframe'}">
            <div class="modals__content alignVertical">
                <div class="modals__content--header alignVertical">
                    <p class="text-center">
                        {{ title }}
                    </p>
                    <a v-if="t==='iframe'" class="fs-iframe" @click.prevent.stop="openModalFS">
                        <i v-if="!modalFs" class="icon expand alternate" style="position: absolute;width: 20px;height: 20px;right: 60px;top: 50%;transform: translateY(-50%);"></i>
                        <i v-else class="icon compress alternate" style="position: absolute;width: 20px;height: 20px;right: 60px;top: 50%;transform: translateY(-50%);"></i>
                    </a>
                    <a href="#" class="close" data-response="0" @click="confirmationResponse">
                        <img src="assets/icons/modals/x.svg" alt="">
                    </a>
                </div>
                <div class="modals__content--body alignVertical">
                    <div :class="{
                        'modalGerais__semMoedas':t!=='iframe'&&t!=='image',
                        'modalGerais__tutorial':t==='iframe'||t==='image',
                        'alignVertical':t!=='iframe'&&t!=='image'}">
                        <div class="modal-container">
                            <div v-if="t==='iframe'">
                                <div class="iframe-container">
                                    <video v-if="iframe_url.includes('.mp4')" controls autoplay>
                                        <source :src="iframe_url" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                    <iframe v-else id="modal_iframe_url" :src="iframe_url" frameborder="0"
                                            allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;fullscreen; picture-in-picture;web-share;"
                                            allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"/>
                                </div>
                            </div>
                            <div v-else-if="t==='image'" ref="text-info" class="alignVertical col-12">
                                <img :src="iframe_url" alt="iframe_url" style="max-width:100%">
                            </div>
                            <div v-else ref="text-info" class="alignVertical col-12">
                                <p v-for="(item,key) in this.txt" :key="key" :class="{'col-12':t==='error'}">
                                    {{ item }}
                                </p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div v-if="['iframe','image'].includes(t) " class="alignVertical col-12"></div>
                            <div v-else-if="['status','success','error'].includes(t)" class="alignVertical col-12">
                                <button v-if="modal_btn !== '' && response!=null" class="button__blue"
                                        @click="confirmationResponse">
                                    {{ btn }}
                                </button>
                                <button v-if="modal_btn !== '' && response==null" class="button__red" @click="close">
                                    {{ btn }}
                                </button>
                            </div>
                            <div v-else class="alignVertical col-12">
                                <div v-if="t== 'confirmationCaptcha'">
                                    <div class="col-12"
                                         :style="'margin-top: 5px;margin-bottom: 10px; justify-content: center;'+(this.switch.captcha?'':'display:none')">
                                        <div v-if="this.switch.captcha_client" class="col-12">
                                            <!-- <img class="float-right" src="assets/images/home/capchat.png"> -->
                                            <!-- <div id="captcha" class="g-recaptcha flex" style="justify-content:center" :data-sitekey="this.switch.captcha_client"></div> -->
                                            <vue-recaptcha ref="recaptcha" :sitekey="this.switch.captcha_client"
                                                           @render="checkCaptcha = false" @verify="this.verifyCaptcha"
                                                           @expired="this.expireCaptcha"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="alignVertical col-6">
                                    <button :class="{'button__blue':logged,'button__secondary':!logged}" data-response="0"
                                            @click="confirmationResponse">
                                        {{ no }}
                                    </button>
                                </div>
                                <div class="alignVertical col-6">
                                    <button v-if="!checkCaptcha" class="button__red button__desactive" data-response="1"
                                            disabled="true">
                                        {{ yes }}
                                    </button>
                                    <button v-else :class="{'button__secondary':logged,'button__blue':!logged}"
                                            data-response="1" @click="confirmationResponse">
                                        {{ yes }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {VueRecaptcha} from "vue-recaptcha";
import {focusUnity} from "@/helper";


export default {
    name: 'Modal',
    props: ['modal_type', 'modal_title', 'modal_text',
        'modal_response', 'modal_yes', 'modal_no', 'modal_btn', 'modal_iframe_url'],
    computed: {
        ...mapGetters([
            'logged', 'token', 'switch', 'checkMobile','isMobile'
        ]),
        isMobileIframe() {
            return window.innerWidth < 450 && this.isMobile && (this.t == 'image' || this.t == 'iframe');
        },
        t() {
            if (this.modal_type)
                return this.modal_type.split("_")[0];
            return this.modal_type;
        },
        sub_t() {
            console.log(this.modal_type);
            if (this.modal_type)
                return this.modal_type.split("_")[1] ?? "";
            return "";
        },
        txt() {
            if (this.modal_text)
                return this.modal_text.split(/[\n\r]/);
            return [];
        }
    },
    data() {
        return {
            response: this.modal_response,
            title: this.modal_title,
            yes: this.modal_yes ? this.modal_yes : this.$i18n.t('modal.sim'),
            no: this.modal_no ? this.modal_no : this.$i18n.t('modal.nao'),
            btn: this.modal_btn ? this.modal_btn : 'OK',
            iframe_url: this.modal_iframe_url,
            checkCaptcha: false,
            responseCaptcha: "",
            modalFs: false
        }
    },
    components: {VueRecaptcha},
    watch: {
        modal_response() {
            this.response = this.modal_response;
        },
        modal_title() {
            this.title = this.modal_title;
        },
        modal_text() {
            this.txt = this.modal_text;
        },
        modal_type() {
            this.t = this.modal_type;
        },
        modal_yes() {
            this.yes = this.modal_yes ? this.modal_yes : this.$i18n.t('modal.sim');
        },
        modal_no() {
            this.no = this.modal_no ? this.modal_no : this.$i18n.t('modal.nao');
        },
        modal_btn() {
            this.btn = this.modal_btn ? this.modal_btn : 'OK';
        },

    },
    created() {

    },
    mounted() {
        //console.log("mounted");
        //console.log(this.t);
        this.$app.bodyOverflow();

        console.log(this.iframe_url);
        if (this.t != 'confirmationCaptcha' || !this.switch.captcha) {
            this.$nextTick(function () {
                this.checkCaptcha = true;    // eslint-disable-next-line no-undef
            })
        }
    },
    updated() {
        if (this.t != 'confirmationCaptcha' || !this.switch.captcha) {
            this.$nextTick(function () {
                this.checkCaptcha = true;    // eslint-disable-next-line no-undef
            })
        }
    },
    beforeDestroy() {
        this.$app.bodyOverflow(false)
    },
    methods: {
        close(e) {
            this.$app.modal_ = false;
            focusUnity(this.checkMobile);
            e.preventDefault();
        },
        confirmationResponse(e) {
            this.close(e);
            let value = Number(e.currentTarget.dataset?.response);
            if (value === 1 && this.t == 'confirmationCaptcha' && this.switch.captcha && this.responseCaptcha.length <= 0) {
                this.$app.modal('error', this.$i18n.t('modal.erro'), this.$i18n.t('modal.vocePrecisaConfirmar'),
                    this.$app.deleteAccount, null, null, "OK");
                return;
            } else if (value === 1 && this.t == 'confirmationCaptcha' && this.switch.captcha) {
                // eslint-disable-next-line no-undef
                this.response(value, this.responseCaptcha);
            } else if (this.response != null)
                this.response(value);

            e.preventDefault();
        },
        verifyCaptcha(CapchaRespose) {
            this.responseCaptcha = CapchaRespose;
            this.checkCaptcha = true;
        },
        expireCaptcha() {

            this.checkCaptcha = false;
        },
        openModalFS() {
            this.modalFs = !this.modalFs;
        }
    },
}
</script>

<style scoped lang="scss">
#modal {
    z-index: 10000 !important;
    background-color: transparent;
    &.isMobileIframe {
        align-items: start;
        &.btnPromotional {
            .bg_background {
                z-index: 10001;
            }
        }
        .modalGerais{
            z-index:10001;
            position: absolute;
            max-width: 100vw;
            min-height: 100vh;
            width: 100%;
            top: 0;
            left: 0;
            padding: 0;
            .fs-iframe{
                display: none;
            }
            .modals__content {
                border-radius: 0;
                width: 100%;
                min-height: 100vh;
                align-items: flex-start;
                gap: 1rem;
            }
        }
    }
    .bg_background{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    .success, .error {
        box-sizing: border-box;
        padding: 15px 0;
    }
    /*----- \footer\ -----*/
    .fs-iframe{
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        text-decoration: none;
    }
    .lightbox-pdf{
        position: absolute;
        max-width: 100vw;
        min-height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
       .modals__content{
            border-radius: 0;
            width: 100%;
            height: 100%;
            min-height: 100vh;
       }
    }
    .iframe-container{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        video,iframe{
            width:100%;
            height:100%;
            max-width: 100%;
            max-height: 80vh;
        }
    }
    .modals__content{
        background-color: rgba(0,0,0,0.5);
        border-radius: 20px;
        backdrop-filter: blur(5px);
        padding: 20px;
        color: #fff;
        .modals__content--header{
            background-color: transparent;
            p{
                font-size: 2rem;
                font-weight: 700;
                line-height: 1.5;
                color: #fff;
                letter-spacing: 0.5px;
            }
        }
        .modals__content--body{
            background-color: transparent;
            .modal-container{
                p{
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #fff;
                    letter-spacing: 0.5px;
                }
            }
            .modal-footer{
                display: flex;
                justify-content: center;
                margin-top: 20px;
                button{
                    margin: 0 10px;
                }
            }
        }
    }
}
</style>
