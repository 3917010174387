<template>
    <div class="modal-translucent">
        <div v-if="!modalInfo.close_background && modalInfo.background" class="modal-translucent-backdrop black"></div>
        <div v-if="modalInfo.close_background " v-on:click="close" class="modal-translucent-backdrop"></div>
        <div :class="`modal-translucent-container ${this.modalInfo.size}`">
            <div class="modal-translucent-header">
                <div v-if="modalInfo.can_close" v-on:click="close" class="close">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1484 2.49219L10.6406 9L17.1484 15.5078L15.5078 17.1484L9 10.6406L2.49219 17.1484L0.851562 15.5078L7.35938 9L0.851562 2.49219L2.49219 0.851562L9 7.35938L15.5078 0.851562L17.1484 2.49219Z" fill="white"/>
                    </svg>
                </div>
            </div>
            <div class="modal-translucent-body">
                <div class="modal-translucent-body-title">{{modalInfo.title}}</div>
                <div class="modal-translucent-body-content">
                    {{modalInfo.content}}
                    <input v-if="modalInfo.with_input" v-model="input_value" :type="modalInfo.input_type" :placeholder="modalInfo.input_placeholder" />
                </div>
            </div>
            <div :class="{'modal-translucent-footer':true,'align-center':!modalInfo.can_cancel}">
                <button @click="confirm" class="confirm">{{modalInfo.confirm_text}}</button>
                <button v-if="modalInfo.can_cancel" @click="cancel" class="cancel">{{modalInfo.cancel_text}}</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name:"TranslucentModal",
        /*
        * modalInfo: {
        *  id: int,
        *  title: string,
        *  content: string,
        *  confirm_text: string,
        *  cancel_text: string,
        *  can_close: boolean,
        *  can_cancel: boolean,
        *  close_background: boolean,
        *  with_input: boolean,
        *  input_placeholder: string,
        *  input_type: string,
        *  callback: function () => void,
        *  callback_cancel: function () => void,
        *  callback_close: function () => void,
        */
        props:['modalInfo'],
        data(){
            return{
                input_value: ''
            }
        },
        methods: {
            confirm(){
                if (this.modalInfo.callback)
                    this.modalInfo.callback(this.modalInfo,this.input_value);
                this.$parent.removeTranslucentModal(this.modalInfo)
            },
            cancel(){
                if (this.modalInfo.callback_cancel)
                    this.modalInfo.callback_cancel(this.modalInfo);
                this.$parent.removeTranslucentModal(this.modalInfo)
            },
            close(){
                if (this.modalInfo.callback_close)
                    this.modalInfo.callback_close(this.modalInfo);
                this.$parent.removeTranslucentModal(this.modalInfo)
            }
        },
    }
</script>
<style scoped>
input{
    border: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: var(--fonts_color);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px 30px;
    display: inline-block;
    margin: 10px;
}
.modal-translucent{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Titillium Web', sans-serif;
}
.modal-translucent-backdrop{
    position: absolute;
    width: 100%;
    height: 100%;
}
.modal-translucent-backdrop.black{
    background-color: rgba(0,0,0,0.7);
}
.modal-translucent-container{
    position: relative;
    min-height: 200px;
    width: 40%;
    background-color: rgba(0,0,0,0.5);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(5px);
}
.modal-translucent-container.sm{
    max-width: min(100%, 350px);
}
@media (max-width: 1000px) {
    .modal-translucent-container{
        width: 80%;
    }
}
.modal-translucent-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 25px;
}
.modal-translucent-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    text-align: center;
}
.modal-translucent-body-title{
    font-weight: 700;
    font-size: 24px;
    color: white;
}
.modal-translucent-body-content{
    color: white;
}
.modal-translucent-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px 35px;
}
.modal-translucent-footer.align-center{
    justify-content: center;
}
.confirm,.cancel{
    border:none;
    font-size:18px;
    font-weight: 400;
    line-height: 22px;
    color: var(--fonts_color);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: var(--buttons_color);
    padding: 10px 30px;
    display: inline-block;
}
.confirm:hover{
    background: var(--fonts_color);
    color: var(--buttons_color)
}
.cancel{
    color: var(--fonts_color_2nd);
    background: var(--buttons_color_2nd);
}
.cancel:hover{
    background: var(--fonts_color_2nd);
    color: var(--buttons_color_2nd)
}
.close{
    background: none;
    border: none;
    color: white;
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: 20px;
    font-size: 30px;
    padding: 0;
    margin: 0;
    line-height: 0;
}
</style>