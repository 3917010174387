<template>
  <div class="lms-content lms-event">

    <!--
    <pre>{{ lms }}</pre>
    -->
    <Slideshow v-if="!slideshowLoading" :slideshowData="slideshowData"></Slideshow>

    <FeaturedCourses class="pos-slideshow" :courses="lms.courses" :lms-slug="lms.slug"></FeaturedCourses>
    <!-- SECTIONS -->

    <!--            <section class="courses-sections-wrapper">-->
    <WatchLives v-if="false" class="watch-lives" ></WatchLives>
    <ContinueLearning class="journey-section"></ContinueLearning>
    <CourseSections class="course-sections"></CourseSections>
    <!--            </section>-->
    <!-- /SECTIONS -->
    <PersonalAdvisor v-if="getLmsConfigs.ai_active" :url="getLmsConfigs.ai_assistant"></PersonalAdvisor>
    <!--        </div>-->

  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import CourseSections from "./components/LmsHomeComponents/CourseSections.vue";
import ContinueLearning from "./components/LmsHomeComponents/ContinueLearning.vue";
import Slideshow from "./components/Slideshow.vue";
import FeaturedCourses from "./components/LmsHomeComponents/FeaturedCourses.vue";
import PersonalAdvisor from "./components/PersonalAdvisor.vue";
import WatchLives from "./components/WatchLives.vue";
const emitter = require('tiny-emitter/instance');

export default {
  name: 'LmsHome',
  computed: {
    ...mapGetters([
      'logged', 'token', 'avatar', 'switch', 'sessions', 'current_session'
    ]),
    ...mapGetters("LMS", ["getLmsConfigs"]),
    lms() {
      return this.$store.getters['LMS/getLms'];
    },
  },
  data() {
    return {
      slideshowData: null,
      slideshowLoading: true
    }
  },
  components: {
    CourseSections,
    ContinueLearning,
    Slideshow,
    FeaturedCourses,
    WatchLives,
    PersonalAdvisor
  },
  created() {
    /*
    window.addEventListener('scroll', this.handleScroll);
     */
  },


  methods: {
    ...mapActions([
      "update_switch"
    ]),
    handleScroll(event) {
      event;
      var scrl_lmt    = 100,
          top         = window.pageYOffset || document.documentElement.scrollTop,
          // left     = window.pageXOffset || document.documentElement.scrollLeft,
          header      = document.getElementById('lms-header');
      if( top > scrl_lmt )    { header.classList.add('scrolled'); }
      else                    { header.classList.remove('scrolled'); }
    }
  },
  beforeMount() {
    document.body.classList.add('lms');
    document.body.classList.add('transparent');

    let root_ = this;
    root_.$parent.wait(true);

    let payload = {
      "slug": root_.$route.params.lmsSlug,
      "language": root_.$i18n.locale
    };

    root_.$store.dispatch("LMS/getListLMS", payload)
        .then(data => {
          // One default slide
          let slideshow = {
            slides: [{
              "active": true,
              "order": 0,
              "content": {
                "title": root_.lms.courses[0]?.title,
                "button_link": root_.lms.courses[0].slug,
                "description": root_.lms.courses[0].description,
                "button_label": "Curso",
                "is_slide_link": false,
                "button_link_new": false,
                "overlay_color": .3
              },
              "file_url": root_.lms.courses[0].thumbnail,
              "type": "image"
            }]
          }
          if(data.slideshow.slides.length){
            slideshow = data.slideshow;
          }
          root_.slideshowData = slideshow;
          root_.slideshowLoading = false;

        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          root_.$parent.wait(false);
          emitter.emit('lms-loaded::finished');
        });

  },
  mounted() {
    /* eslint-disable no-unused-vars */
    /* global $ */
    // $.toast({
    //   title: 'Olá',
    //   message: 'Seja bem-vindo!',
    //   class: 'center aligned', // you can also use 'centered'
    //   color: 'success'
    // });
    // $.suimodal({
    //   title: 'Important Notice',
    //   class: 'mini',
    //   closeIcon: true,
    //   content: 'You will be logged out in 5 Minutes',
    //   actions: [{
    //     text: 'Alright, got it',
    //     class: 'green'
    //   }]
    // }).suimodal('show');
    /* eslint-enable no-unused-vars */
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {

  },
}
</script>

<style lang="scss" scoped>
.pos-slideshow {
  z-index: 1;
  margin-top: -212px !important;
}

.watch-lives {
  margin-top: 60px;
}

.journey-section {
  margin-top: 20px;
}
.course-sections {
  margin-top: 20px;
}

</style>
