<template>
    <div id="modal_badge" ref="modal_badge" class="modals bgBlack" style="z-index: 99999;">
        <div class="badge-container">
            <div class="badge-data">
              <div class="header">
                <span class="badge_header">{{$t("modal_badges.congratulations")}}</span>
              </div>
              <div class="text-elements">
                <span class="badge_name" >{{ badge_name }}</span>
                <span class="badge_description" >{{ badge_text }}</span>
              </div>
              <div class="badge_img">
                  <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(badge_file)" :src="badge_file">
                      <source :src="badge_file">
                  </video>
                  <img v-else :src="badge_file">
              </div>

            </div>
            
            <button class="button_badge" @click.prevent="close">{{$t("modal_badges.confirm")}}</button>
        </div>

    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'ModalBadge',
    props: ['badge_file','badge_name','badge_text','badge_external_url',],
    computed: {
        ...mapGetters([
            'logged', 'token','switch'
        ]),
        // isMobileIframe(){
        //     return window.innerWidth < 450 && this.$app.isMobile && (this.t == 'image' || this.t == 'iframe');
        // },
        // t(){
        //     if (this.modal_type)
        //         return this.modal_type.split("_")[0];
        //     return this.modal_type;
        // },
        // sub_t(){
        //     console.log(this.modal_type);
        //     if (this.modal_type)
        //         return this.modal_type.split("_")[1]??"";
        //     return "";
        // },
        // txt(){
        //     if (this.modal_text)
        //         return this.modal_text.split(/[\n\r]/);
        //     return [];
        // }
    },
    data() {
        return {
            openModal: false,
            badge_title_: "",
            badge_url_: "",
            badge_external_url_: "",
        }

    },
    methods: {
        close() {
            this.$app.modal_iframe = false
            this.$app.modal_ = false
            this.$app.modal_badge_ = false;

          // With this postMessage, it will close the lightbox too
          // document.getElementById('iframe').contentWindow.postMessage({message_type:"external",data: "krpano_modal", params: {}}, '*')

        },
        // openModalWith(badge_file,badge_name,badge_external_url){
        //     this.openModal = true;
        //     this.badge_title_ = badge_name;
        //     if (badge_external_url != null)
        //         this.badge_external_url_ = badge_external_url;
        //     this.badge_url_ = badge_file;
        // },
    },
    created() {

    },
    mounted() {
        //console.log("mounted");
        //console.log(this.t);
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>

.bgBlack {
  background: rgba(0, 0, 0, 0.4);
}

.badge-container {
    padding: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 420px;
    height: 520px;
    background-color: #00000090;
    backdrop-filter: blur(10px);
    border-radius: 30px;
    flex-direction: column;
}
img, video {
    object-fit: cover;
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    border-radius: 15px;
}

.text-elements{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.badge_img {
  margin-top: 15px;
}
.badge_header {
  text-align: center;
  font-size: 30px;
  color: #ffffff;
  display:block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.badge_name {
    max-width: 200px;
    width: 200px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--buttons_color);
    display:block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.badge_description {
  max-width: 200px;
  overflow: hidden;
  font-size: 13px;
  flex-wrap: wrap;
  text-align: center;
  word-break: break-word;
  color: #FFFFFF;
  display: block;
  margin: 10px 0;
}

.button_badge {
    background-color: var(--buttons_color);
    color: #ffffff;
    width: 139px;
    height: 50px;
    border:none;
    border-radius: 10px;
    margin-top: 20px;
}

.badge-data {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    height:290px;
    margin:-30px 0px 10px;
}
.close{
cursor: pointer;
position: relative;
right: -165px;
top: 0;
}
</style>